import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import TemplateDesign from '../components/main/TemplateDesign';
import MyImages from '../components/MyImages';
import Projects from '../components/Projects';
import Image from '../components/Image';
import CreateComponent from '../components/CreateComponent';
import Draw from '../components/Draw';
import { fontList, generateRandom4DigitNumber } from '../helper/Helpers';
import PsdUploader from '../components/PSDFiles';
import { GetImages } from '../ServerApi/server';
import { CONNECTION_REFUSED } from '../helper/Helpers';
import { TbBackground } from "react-icons/tb";
import Background from '../components/Background';
import Pdf from '../components/Pdf';
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { PlaceHolder } from '../helper/PlaceHolder';
import { FaTools } from "react-icons/fa";

export default function Main() {

    const [currentComponent, setCurrentComponent] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const [color, setColor] = useState('');
    const [image, setImage] = useState('');
    const [rotate, setRotate] = useState(0);
    const [state, setState] = useState('pdf');
    const [left, setLeft] = useState('');
    const [top, setTop] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [opacity, setOpacity] = useState('');
    const [zIndex, setzIndex] = useState('');
    const [padding, setPadding] = useState('');
    const [font, setFont] = useState('');
    const [weight, setWeight] = useState();
    const [text, setText] = useState('');
    const [radius, setRadius] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState('')
    const [selectedFont, setSelectedFont] = useState(fontList[0]);
    const [drawFontWeight, setDrawFontWeight] = useState(2);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const [drawState, setDrawState] = useState('');
    const [drawStateColor, setDrawStateColor] = useState('');
    const [drawOption, setDrawOption] = useState(false);

    // Render PDF
    const [zoom, setZoom] = useState(48);
    const [pdfPages, setPdfPages] = useState(null);
    GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;
    const [currentPage, setCurrentPage] = useState(1);
    const pageRefs = useRef([]);
    const containerRef = useRef(null);
    const [position, setPosition] = useState({ top: 158, right: 50 });
    const [isDragging, setIsDragging] = useState(false);
    const [startOffset, setStartOffset] = useState({ x: 0, y: 0 });
    const [editOptions, setEditOptions] = useState({
        width: 0,
        height: 0,
        rotate: 0,
        opacity: 1,
        fontSize: 0,
        fontFamily: '',
        color: '',
        backgroundColor: '',
        bold: false,
        italic: false,
        underline: false,
        left: false,
        middle: false,
        right: false
    });

    const [show, setShow] = useState({
        status: true,
        name: ''
    });

    const [myImages, setMyImages] = useState([]);

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const fetchImages = async (imageType) => {
        try {
            const getImagesApiResponse = await GetImages(imageType, null);
            if (getImagesApiResponse.status === 200) {
                setMyImages([]);
                setMyImages(getImagesApiResponse.data.design_assets);
            } else {
                setPopupMessage(getImagesApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    };

    const setElements = (type, name, imageType) => {
        if (imageType !== null) {
            fetchImages(imageType);
        }
        setState(type);
        setShow({
            state: false,
            name
        })
    };

    const moveElement = (id, currentInfo) => {
        setCurrentComponent(currentInfo);
        let isMoving = true;
        const currentDiv = document.getElementById(id);
        const mouseMove = ({ movementX, movementY }) => {
            const getStyle = window.getComputedStyle(currentDiv)
            const left = parseInt(getStyle.left);
            const top = parseInt(getStyle.top);
            if (isMoving) {
                currentDiv.style.left = `${left + movementX}px`;
                currentDiv.style.top = `${top + movementY}px`;
            }
        }

        const mouseUp = (e) => {
            isMoving = false;
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);
            setLeft(parseInt(currentDiv.style.left));
            setTop(parseInt(currentDiv.style.top));
        }

        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
    }

    const resizeElement = (id, currentInfo) => {
        setCurrentComponent(currentInfo);
        let isMoving = true;
        const currentDiv = document.getElementById(id);
        const mouseMove = ({ movementX, movementY }) => {
            const getStyle = window.getComputedStyle(currentDiv);
            const width = parseInt(getStyle.width);
            const height = parseInt(getStyle.height);
            if (isMoving) {
                currentDiv.style.width = `${width + movementX}px`;
                currentDiv.style.height = `${height + movementY}px`;
            }
        }

        const mouseUp = (e) => {
            isMoving = false;
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);
            setWidth(parseInt(currentDiv.style.width));
            setHeight(parseInt(currentDiv.style.height));
        }
        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);

        currentDiv.ondragstart = function () {
            return false;
        };
    };

    const rotateElement = (id, currentInfo) => {
        setCurrentComponent('');
        setCurrentComponent(currentInfo);
        const target = document.getElementById(id);
        const mouseMove = ({ movementX, movementY }) => {
            const getStyle = window.getComputedStyle(target);
            const trans = getStyle.transform;
            const values = trans.split('(')[1].split(')')[0].split(',');
            const angle = Math.round(Math.atan2(values[1], values[0]) * (180 / Math.PI));
            let deg = angle < 0 ? angle + 360 : angle;
            if (movementX) {
                deg = deg + movementX;
            }

            target.style.transform = `rotate(${deg}deg)`;
        }

        const mouseUp = (e) => {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseUp);
            const getStyle = window.getComputedStyle(target);
            const trans = getStyle.transform;
            const values = trans.split('(')[1].split(')')[0].split(',');
            const angle = Math.round(Math.atan2(values[1], values[0]) * (180 / Math.PI));
            let deg = angle < 0 ? angle + 360 : angle;
            setRotate(deg);
        }

        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);

        target.ondragstart = function () {
            return false;
        };
    };

    const [mainFrameId, setMainFrameId] = useState('');

    const componentStyle = {
        name: 'main_frame',
        type: 'rect',
        id: generateRandom4DigitNumber(),
        pageCount: 0,
        height: 480,
        width: 240,
        z_index: 1,
        color: '#fff',
        image: '',
        setCurrentComponent: (a) => setCurrentComponent(a)
    };

    const [components, setComponents] = useState([componentStyle]);

    const newComponent = () => {
        const countMainFrames = components.filter(component => component.name === 'main_frame').length;
        const style = {
            name: 'main_frame',
            type: 'rect',
            id: generateRandom4DigitNumber(),
            pageCount: countMainFrames,
            height: 480,
            width: 240,
            z_index: 1,
            color: '#fff',
            image: image ? image : '',
            setCurrentComponent: (a) => setCurrentComponent(a)
        }
        setMainFrameId(style.id);
        setComponents(prevState => ([...prevState, style]));
    }

    const removeComponent = (info) => {
        let temp = components.filter(c => c.id !== info.id);
        temp = temp.filter(c => c.mainFrameId !== info.id);
        let updatedComponents = [];

        temp.forEach(c => {
            if (c.name === 'main_frame' && c.pageCount > info.pageCount) {
                let sub_components = temp.filter(com => com.mainFrameId === c.id);
                sub_components = sub_components.map(sub => ({
                    ...sub,
                    top: sub.top - 500
                }));
                updatedComponents.push({
                    ...c,
                    pageCount: c.pageCount - 1
                });
                updatedComponents = updatedComponents.concat(sub_components);
            } else {
                if (!updatedComponents.some(comp => comp.id === c.id)) {
                    updatedComponents.push(c);
                }
            }
        });
        updatedComponents = updatedComponents.flat();
        setCurrentComponent('');
        setComponents(updatedComponents);
    };

    const removeBackground = () => {
        const com = components.find(c => c.id === currentComponent.id);
        com.image = '';
        setImage('');
    };

    const opacityHandle = (e) => {
        setOpacity(parseFloat(e.target.value));
    }

    const createShape = (name, type) => {
        const id = Date.now();
        setDrawOption(false);
        const style = {
            id: id,
            mainFrameId: mainFrameId,
            name: name,
            type,
            left: 10,
            top: 10,
            opacity: 1,
            width: 200,
            height: 150,
            rotate,
            z_index: 2,
            color: '#3c3c3d',
            setCurrentComponent: (a) => setCurrentComponent(a),
            removeBackground: () => setImage(''),
            moveElement,
            resizeElement,
            rotateElement,
        }
        setComponents([...components, style]);
    }

    const updateTextComponent = (id, newText) => {
        setComponents(components.map(comp => comp.id === id ? { ...comp, title: newText } : comp));
    };

    const add_text = (name, type, option) => {
        const id = Date.now();
        setDrawOption(false);
        const style = {
            id: id,
            mainFrameId: mainFrameId,
            name: name,
            type,
            left: 10,
            top: 10,
            opacity: 1,
            rotate,
            z_index: 10,
            padding: 6,
            font: option === 'textBox' ? 22 : option === 'heading' ? 28 : 24,
            title: option === 'textBox' ? 'Add text' : option === 'heading' ? 'Add heading' : 'Add sub heading',
            weight: option === 'textBox' ? 400 : option === 'heading' ? 600 : 500,
            color: '#3c3c3d',
            fontFamily: selectedFont,
            setCurrentComponent: (a) => setCurrentComponent(a),
            moveElement,
            resizeElement,
            rotateElement,
        }
        setSelectedFont('');
        setColor('');
        setWeight('');
        setFont('');
        setCurrentComponent(style);
        setComponents([...components, style]);
    }

    const add_image = (img) => {
        const id = Date.now();
        setDrawOption(false);
        const style = {
            id: id,
            mainFrameId: mainFrameId,
            name: 'image',
            type: 'image',
            left: 10,
            top: 10,
            opacity: 1,
            width: 161,
            height: 161,
            rotate,
            z_index: 2,
            ratius: 0,
            image: img,
            color: '',
            setCurrentComponent: (a) => setCurrentComponent(a),
            moveElement,
            resizeElement,
            rotateElement,
        }
        setCurrentComponent(style);
        setComponents([...components, style]);
    }

    const fetchSVGPath = async (url, color) => {
        const text = atob(url.split(',')[1]);
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'image/svg+xml');
        const svgElement = doc.querySelector('svg');
        const gElement = doc.querySelector('g');
        const paths = gElement.querySelectorAll('path');

        paths.forEach(path => {
            path.setAttribute('fill', color);
        });

        const viewBox = svgElement.getAttribute('viewBox');
        const gInnerHTML = gElement ? gElement.innerHTML : '';

        return {
            'pathData': paths.length > 0 ? paths[0].getAttribute('d') : '',
            'viewBox': viewBox ? viewBox : '',
            'gInnerHTML': gInnerHTML ? gInnerHTML : ''
        };
    };


    const add_element = async (img) => {
        const id = Date.now();
        setDrawOption(false);
        const svgPathData = await fetchSVGPath(img, '#373435');
        const style = {
            id: id,
            mainFrameId: mainFrameId,
            name: 'svg',
            type: 'svg',
            left: 10,
            top: 10,
            opacity: 1,
            width: 200,
            height: 150,
            rotate,
            z_index: 2,
            radius: 0,
            image: img,
            color: color,
            pathData: svgPathData.pathData,
            viewBox: svgPathData.viewBox,
            backgroundColor: backgroundColor,
            gInnerHTML: svgPathData.gInnerHTML,
            setCurrentComponent: (a) => setCurrentComponent(a),
            moveElement,
            resizeElement,
            rotateElement,
        };
        setBackgroundColor('');
        setCurrentComponent(style);
        setComponents([...components, style]);
    };

    const showPdfUrl = (pdf) => {
        setPdfUrl(pdf.url);
        setPdfPages(pdf.pages);
    };

    const handleMouseDown = (e) => {
        const targetTagName = e.target.tagName.toLowerCase();
        if (['input', 'textarea', 'select', 'button'].includes(targetTagName)) {
            return;
        }
        const container = containerRef.current;
        const rect = container.getBoundingClientRect();
        const right = window.innerWidth - rect.right;

        setStartOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
            right: right,
        });

        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const newRight = window.innerWidth - e.clientX - (startOffset.x);

        setPosition({
            top: e.clientY - startOffset.y,
            right: newRight,
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const getElementStyles = (data = {}) => {
        if (Object.keys(data).length) {
            setEditOptions(data);
        }
    };

    useEffect(() => {
        if (currentComponent) {
            setComponents((prevComponents) => {
                return prevComponents.map((component) => {
                    if (component.id === currentComponent.id) {
                        if (currentComponent.name !== 'text') {
                            component.width = width || currentComponent.width;
                            component.height = height || currentComponent.height;
                            component.rotate = rotate || currentComponent.rotate;
                        }
                        if (currentComponent.name === 'text') {
                            component.font = font || currentComponent.font;
                            component.padding = padding || currentComponent.padding;
                            component.weight = weight || currentComponent.weight;
                            component.title = text || currentComponent.title;
                            component.fontFamily = selectedFont || currentComponent.fontFamily;
                        }
                        if (currentComponent.name === 'image') {
                            component.radius = radius || currentComponent.radius;
                        }
                        if (currentComponent.name === 'main_frame') {
                            component.image = image || currentComponent.image;
                        }

                        if (currentComponent.name !== 'svg') {
                            component.color = color || currentComponent.color;
                        }


                        if (currentComponent.name === 'svg' && color) {
                            const updatedSvg = async () => {
                                const svgPathData = await fetchSVGPath(component.image, color);
                                component.gInnerHTML = svgPathData.gInnerHTML;
                            };
                            updatedSvg();
                        }

                        if (currentComponent.name !== 'main_frame') {
                            component.left = left || currentComponent.left;
                            component.top = top || currentComponent.top;
                            component.opacity = opacity || currentComponent.opacity;
                            component.z_index = zIndex || currentComponent.z_index;
                        }
                        if (currentComponent.name === 'svg' && backgroundColor) {
                            component.backgroundColor = backgroundColor || currentComponent.backgroundColor;
                        }
                    }
                    return component;
                });
            });

            setSelectedFont('');
            setColor('');
            setWidth('');
            setHeight('');
            setTop('');
            setLeft('');
            setRotate(0);
            setOpacity('');
            setzIndex('');
            setText('');
            setBackgroundColor('');
        }
    }, [color, image, left, top, width, height, opacity, zIndex, padding, font,
        weight, text, radius, backgroundColor, selectedFont]);

    useEffect(() => {
        const loadPdf = async () => {
            const loadingTask = getDocument(pdfUrl);
            const pdf = await loadingTask.promise;

            for (let index = 1; index < pdf.numPages; index++) {
                if (components?.length <= index) {
                    newComponent();
                }
            }

            if (components?.length > pdf.numPages) {
                document.querySelectorAll('[name="main_frame"]').forEach(function (element, index) {
                    if (index >= pdf.numPages) {
                        setTimeout(() => {
                            element.remove();
                        }, 100);
                    }
                });

                setComponents(prevState => ([...prevState].slice(0, pdf.numPages)));
            }
        };
        if (pdfUrl) {
            loadPdf();
        }
    }, [pdfUrl]);

    useEffect(() => {

        const handleKeyDown = (e) => {
            if (e.ctrlKey) {
                if (e.key === '+') {
                    setZoom((prevZoom) => Math.min(prevZoom + 2, 100));
                    e.preventDefault();
                } else if (e.key === '-') {
                    setZoom((prevZoom) => Math.max(prevZoom - 2, 48));
                    e.preventDefault();
                }
            }
        };

        const handleEventClick = (event) => {
            if (event.target.id === "pdfContainer") {
                setCurrentComponent('');
                setShow({ name: '', status: true });
                setState('');
            }
        }

        const handleDomLoad = () => {
            setIsPageLoaded(true);
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', handleEventClick);
        window.addEventListener('load', handleDomLoad);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('click', handleEventClick);
            window.removeEventListener('load', handleDomLoad);
        };

    }, []);

    useEffect(() => {
        let isIndexZero = false;
        const handleScroll = () => {
            pageRefs.current.forEach((page, index) => {
                if (page) {
                    const rect = page.getBoundingClientRect();
                    const isInViewport = rect.top >= 0 && rect.top < window.innerHeight * 0.7;
                    if (isInViewport) {
                        if (index === 0 || isIndexZero) {
                            index++;
                            isIndexZero = true;
                        }
                        setCurrentPage(index);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageRefs.current]);

    return (
        <React.Fragment>
            {!isPageLoaded ? <PlaceHolder /> : (
                <div className='min-w-screen bg-[#f6f7f8]'>
                    <Header />
                    <div className='flex h-[calc(100%-60px)]'>

                        {/* sidebar menu */}
                        <div className='fixed mt-[60px] w-[80px] h-[100%] bg-[#f6f7f8] text-[rgba(0,0,0,0.6)] overflow-y-auto z-30'>

                            <div onClick={() => setElements('design', 'design')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group" >
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#13a3b5] ${state === 'design' && "text-[#13a3b5]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3 11.325c0-3.59 0-5.385.966-6.61a4.5 4.5 0 0 1 .748-.749C5.94 3 7.734 3 11.325 3h1.35c3.59 0 5.386 0 6.61.966.279.22.53.47.749.748C21 5.94 21 7.734 21 11.325v1.35c0 3.59 0 5.386-.966 6.61-.22.279-.47.53-.748.749-1.226.966-3.02.966-6.611.966h-1.35c-3.59 0-5.385 0-6.61-.966a4.497 4.497 0 0 1-.749-.748C3 18.06 3 16.266 3 12.675v-1.35ZM11.325 4.5H13.5v15h-2.175c-1.83 0-3.076-.002-4.021-.111-.914-.105-1.356-.293-1.661-.533a3.004 3.004 0 0 1-.499-.499c-.24-.305-.428-.747-.533-1.661-.109-.945-.111-2.19-.111-4.021v-1.35c0-1.83.002-3.076.11-4.021.106-.914.293-1.356.534-1.661a3 3 0 0 1 .499-.499c.305-.24.747-.428 1.661-.533.945-.109 2.19-.111 4.021-.111ZM15 19.486c.666-.014 1.22-.042 1.696-.097.914-.105 1.356-.293 1.661-.533.186-.146.353-.314.499-.499.24-.305.428-.747.533-1.661.109-.945.111-2.19.111-4.021v-1.657H15v8.468Zm4.494-9.968c-.01-.904-.037-1.619-.105-2.214-.105-.914-.293-1.356-.533-1.661a3.004 3.004 0 0 0-.499-.499c-.305-.24-.747-.428-1.661-.533A18.58 18.58 0 0 0 15 4.514v5.004h4.494Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Design</span>
                            </div>

                            <div onClick={() => setElements('element', 'element', 'svg')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'element' && "text-[#992bff]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.55 11.242a1.502 1.502 0 0 0 1.42 0l.002-.002.004-.002.01-.005.026-.015.084-.048a9.986 9.986 0 0 0 1.14-.787c.638-.51 1.478-1.312 2.026-2.375a2.991 2.991 0 0 0-4.003-4.16A2.991 2.991 0 0 0 3.2 7.912c.54 1.103 1.402 1.93 2.055 2.454a10.007 10.007 0 0 0 1.25.854l.028.015.01.005.003.002.002.001ZM4.53 7.217c.352.731.918 1.34 1.444 1.794a8.454 8.454 0 0 0 1.285.91s.086-.047.229-.136a8.452 8.452 0 0 0 1.054-.776c.51-.442 1.058-1.03 1.41-1.734a1.491 1.491 0 1 0-2.693-1.208 1.493 1.493 0 0 0-1.435-1.084A1.491 1.491 0 0 0 4.53 7.217ZM17.536 4.011a1.026 1.026 0 0 0-1.775 0l-3.307 5.694a1.026 1.026 0 0 0 .888 1.542h6.614c.79 0 1.285-.857.887-1.542l-3.307-5.694ZM16.65 5.47l-2.485 4.277h4.969L16.649 5.47ZM20.861 17.168a4.213 4.213 0 1 1-8.425 0 4.213 4.213 0 0 1 8.425 0Zm-1.5 0a2.713 2.713 0 1 1-5.425 0 2.713 2.713 0 0 1 5.425 0ZM3.406 14.086a.77.77 0 0 1 .77-.77h6.14a.77.77 0 0 1 .77.77v6.294c0 .34-.275.616-.615.616H4.176a.77.77 0 0 1-.77-.77v-6.14Zm1.5 5.41v-4.68h4.68v4.68h-4.68Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Elements</span>
                            </div>

                            <div onClick={() => setElements('draw', 'draw')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#0ba84a] ${state === 'draw' && "text-[#0ba84a]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M20.07 3.456a3.135 3.135 0 0 0-4.434 0L10.25 8.843a3.38 3.38 0 0 0-.884 1.55l-.845 3.292c-.205.8.522 1.527 1.322 1.323l3.278-.837a3.384 3.384 0 0 0 1.555-.886L20.07 7.89a3.135 3.135 0 0 0 0-4.434Zm-2.117 4.43 1.057-1.057a1.635 1.635 0 0 0-2.313-2.313l-1.056 1.057 2.312 2.312Zm-1.166 1.166-3.172 3.172c-.24.24-.539.41-.866.493l-2.602.665.67-2.616a1.88 1.88 0 0 1 .492-.862l3.165-3.164 2.313 2.312Z" fill="currentColor"></path><path d="M5.144 15.022a.641.641 0 1 0 0 1.282h13.751a2.109 2.109 0 0 1 0 4.218H9.194a.75.75 0 0 1 0-1.5h9.701a.609.609 0 1 0 0-1.218H5.144a2.141 2.141 0 0 1 0-4.282h1.862v1.5H5.144Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Draw</span>
                            </div>

                            <div onClick={() => setElements('image', 'uploadImage')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#ff6105] ${state === 'image' && "text-[#ff6105]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.24 2.985c-2.735 0-5.04 2.075-5.316 4.788a.199.199 0 0 1-.123.162 5.729 5.729 0 0 0 1.994 11.097 5.727 5.727 0 0 0 5.727-5.727v-.486l1.782 1.782a.75.75 0 0 0 1.06-1.06l-3.062-3.063a.75.75 0 0 0-1.06 0L9.179 13.54a.75.75 0 0 0 1.06 1.06l1.783-1.781v.486A4.227 4.227 0 1 1 6.324 9.34a1.698 1.698 0 0 0 1.092-1.416c.198-1.943 1.855-3.44 3.825-3.44a3.848 3.848 0 0 1 3.785 3.174c.135.764.78 1.366 1.563 1.43 2.146.178 3.855 2.016 3.855 4.216a4.226 4.226 0 0 1-4.227 4.227h-1.914a.75.75 0 0 0 0 1.5h1.914a5.727 5.727 0 0 0 5.727-5.727c0-2.978-2.305-5.468-5.231-5.71a.25.25 0 0 1-.21-.196 5.348 5.348 0 0 0-5.262-4.414Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Upload</span>
                            </div>

                            <div onClick={() => setElements('text', 'text')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'text' && "text-[#992bff]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.266 5.792a1.5 1.5 0 0 1 1.5-1.5h12.468a1.5 1.5 0 0 1 1.5 1.5v1.85a.75.75 0 0 1-1.5 0v-1.35a.5.5 0 0 0-.5-.5H12.75v11.939a.5.5 0 0 0 .5.5h1.875a.75.75 0 0 1 0 1.5h-6.25a.75.75 0 1 1 0-1.5h1.875a.5.5 0 0 0 .5-.5V5.792H6.266a.5.5 0 0 0-.5.5V7.67a.75.75 0 1 1-1.5 0V5.792Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Text</span>
                            </div>

                            <div onClick={() => setElements('pdf', 'pdf')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#333] ${state === 'pdf' && "text-[#333]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.845 2.942H7.03a4 4 0 0 0-4 3.994l-.017 10a4 4 0 0 0 4 4.006h9.993a4 4 0 0 0 4-4v-8.23a3 3 0 0 0-3-3h-3.614a.5.5 0 0 1-.447-.277l-.417-.834a3 3 0 0 0-2.683-1.659Zm-3.815 1.5h3.815a1.5 1.5 0 0 1 1.341.83l.417.834a2 2 0 0 0 1.79 1.106h3.613a1.5 1.5 0 0 1 1.5 1.5v.735H4.526l.004-2.509a2.5 2.5 0 0 1 2.5-2.495Zm-2.507 6.505-.01 5.991a2.5 2.5 0 0 0 2.5 2.505h9.993a2.5 2.5 0 0 0 2.5-2.5v-5.996H4.523Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>PDF</span>
                            </div>

                            <div onClick={() => setElements('initImage', 'images', 'png')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'initImage' && "text-[#992bff]"}`}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.81 4.5h-1.62c-1.765 0-2.965.002-3.878.104-.884.098-1.315.273-1.613.497a3 3 0 0 0-.598.598c-.224.298-.4.729-.497 1.613-.102.913-.104 2.113-.104 3.878v1.62c0 1.765.002 2.965.104 3.878.085.77.23 1.196.413 1.49l7.773-7.773.03-.03c.484-.484.89-.89 1.256-1.183.384-.307.79-.546 1.287-.616.245-.035.495-.035.74 0 .496.07.903.309 1.287.616.365.292.772.7 1.257 1.184l.03.03.823.823v-.039c0-1.765-.002-2.965-.104-3.878-.098-.884-.273-1.315-.497-1.613a3.003 3.003 0 0 0-.598-.598c-.298-.224-.729-.4-1.613-.497-.913-.102-2.113-.104-3.878-.104ZM7.312 19.396c-.515-.057-.877-.14-1.147-.244l7.685-7.686c.522-.522.87-.869 1.163-1.103.28-.224.439-.285.561-.302.106-.015.212-.015.318 0 .122.017.28.078.56.302.293.234.642.581 1.164 1.103L19.5 13.35c-.002 1.475-.013 2.521-.104 3.338-.098.884-.273 1.315-.497 1.613-.17.227-.371.428-.598.598-.298.224-.729.4-1.613.497-.913.102-2.113.104-3.878.104h-1.62c-1.765 0-2.965-.002-3.878-.104ZM3.902 4.798C3 5.998 3 7.73 3 11.19v1.62c0 3.46 0 5.191.901 6.392.255.34.557.641.897.897C5.998 21 7.73 21 11.19 21h1.62c3.46 0 5.191 0 6.392-.901.34-.256.641-.557.897-.897.901-1.2.901-2.931.901-6.392v-1.62c0-3.46 0-5.191-.901-6.392a4.501 4.501 0 0 0-.897-.897C18.002 3 16.27 3 12.81 3h-1.62c-3.46 0-5.191 0-6.392.901a4.5 4.5 0 0 0-.897.897ZM8.5 10.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span className='text-xs font-medium'>Images</span>
                            </div>

                            <div onClick={() => setElements('background', 'background', 'background')} className="w-full h-[80px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                                <span className='text-2xl w-[35px] h-20px p-1 flex justify-center rounded-md group-hover:bg-slate-100'><TbBackground className={`text-[25px] flex justify-center items-center group-hover:text-[#333] ${state === 'background' && "text-[#333]"}`} /></span>
                                <span className='text-xs font-medium'>Background</span>
                            </div>
                        </div>

                        <div className='w-[calc(100%-0px)]'>
                            <div className={`${show.status ? 'p-0 -left-[50%]' : 'px-4 left-[85px] py-5'} bg-[#f6f7f8] shadow-lg h-full fixed ${state === 'draw' ? 'w-[280px]' : 'w-[350px]'} z-30`} style={{ marginTop: '60px' }}>
                                <div onClick={() => { setShow({ name: '', status: true }); setState(''); }} className='flex absolute justify-center items-center w-[20px] -right-2 text-slate-300 top-[40%] cursor-pointer h-[100px] rounded-full shadow-md bg-[#f1f1f1] border-[2px] border-solid border-[rgba(0,0,0,0.08)]' >
                                    < MdKeyboardArrowLeft className='text-[#333]' />
                                </div>
                                {
                                    state === 'design' && <div>
                                        <div className='grid grid-cols-2 gap-2'>
                                            {<TemplateDesign />}
                                            {<PsdUploader psdPath="src/assets/CV-Template-01.psd" />}
                                        </div>
                                    </div>
                                }
                                {
                                    state === 'shape' && <div className='grid grid-cols-3 gap-2'>
                                        <div onClick={() => createShape('shape', 'rect')} className='h-[90px] bg-[#3c3c3d] cursor-pointer'></div>
                                        <div onClick={() => createShape('shape', 'circle')} className='h-[90px] bg-[#3c3c3d] cursor-pointer rounded-full'></div>
                                        <div onClick={() => createShape('shape', 'trangle')} style={{ clipPath: 'polygon(50% 0, 100% 100%, 0 100%)' }} className='h-[90px] bg-[#3c3c3d] cursor-pointer'></div>
                                    </div>
                                }
                                {
                                    state === 'image' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <MyImages add_image={add_image} />
                                    </div>
                                }
                                {
                                    state === 'text' && <React.Fragment><div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <button onClick={() => add_text('text', 'title', 'textBox')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a text box
                                            </button>
                                        </div>
                                    </div>
                                        <div className="text-[#333] my-[21px] text-[17px] font-bold">
                                            Default text styles
                                        </div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <p onClick={() => add_text('text', 'title', 'heading')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a heading
                                            </p>
                                        </div>
                                        <div className='grid grid-cols-1 gap-2'>
                                            <p onClick={() => add_text('text', 'title', 'subheading')} className='bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer font-[400] p-3 text-[14px] text-[#333] text-xl rounded-md border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px]'>
                                                Add a sub heading
                                            </p>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    state === 'project' && <Projects />

                                }
                                {
                                    state === 'pdf' && (
                                        <div className='h-[80vh] overflow-x-auto flex justify-start items-start scrollbar-hide'>
                                            <Pdf setPdfUrl={setPdfUrl} showPdfUrl={showPdfUrl} />
                                        </div>
                                    )
                                }
                                {
                                    state === 'element' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Image add_image={add_image} add_element={add_element} element={'element'} myImages={myImages} />

                                    </div>
                                }
                                {
                                    state === 'initImage' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Image add_image={add_image} add_element={add_element} element={'image'} myImages={myImages} />
                                    </div>
                                }
                                {
                                    state === 'background' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Background myImages={myImages} setImage={setImage} />
                                    </div>
                                }
                                {
                                    state === 'draw' && <div className='h-[80vh] overflow-x-auto flex justify-start items-start 
                                        scrollbar-hide'>
                                        <Draw setDrawState={setDrawState} setDrawStateColor={setDrawStateColor}
                                            setDrawOption={setDrawOption} setDrawFontWeight={setDrawFontWeight} />
                                    </div>
                                }
                            </div>
                            <div className='w-full flex'>
                                <div className="justify-center items-center h-full min-h-[100vh] w-full overflow-y-auto bg-[#f6f7f8]">
                                    <div id='pdfContainer' className='m-w-[650px] m-h-[480px] flex justify-center items-center overflow-hidden'>
                                        <div className='my-[50px]'>
                                            <div className='w-auto h-auto overflow-hidden mt-[93px]'>
                                                {components.map((c, i) => (
                                                    <div ref={(el) => (pageRefs.current[i] = el)} data-pageid={i + 1}>
                                                        <CreateComponent
                                                            key={i}
                                                            info={c}
                                                            zoom={zoom}
                                                            current_component={currentComponent}
                                                            removeComponent={removeComponent}
                                                            setText={updateTextComponent}
                                                            setMainFrameId={setMainFrameId}
                                                            setImage={setImage}
                                                            allComponents={components}
                                                            setCurrentComponent={setCurrentComponent}
                                                            setComponents={setComponents}
                                                            components={components}
                                                            mainFrameId={mainFrameId}
                                                            drawState={drawState}
                                                            drawStateColor={drawStateColor}
                                                            drawOption={drawOption}
                                                            moveElement={moveElement}
                                                            resizeElement={resizeElement}
                                                            rotateElement={rotateElement}
                                                            setDrawOption={setDrawOption}
                                                            drawFontWeight={drawFontWeight}
                                                            pdfPages={pdfPages}
                                                            pageIndex={i + 1}
                                                            getElementStyles={getElementStyles}
                                                            setElementStyles={editOptions}
                                                        />
                                                    </div>
                                                ))}
                                                <div className="add-page-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '19px', marginBottom: '20px' }}>
                                                    <button className="rounded bg-[#f6f7f8] hover:bg-[rgb(0,0,0,0.02)] cursor-pointer p-3 text-[14px] text-[#000] font-[500] border-[1px] solid border-[rgba(0,0,0,0.2)] flex items-center justify-center max-h-[45px] w-full"
                                                        onClick={newComponent}>
                                                        + Add Page
                                                    </button>
                                                </div>
                                                <div className='min-h-[35px] w-full bg-[#f6f7f8] fixed bottom-0 right-0 z-20 flex justify-end items-center'>
                                                    <div className='absolute w-[45%] h-full'>
                                                        <div className='flex gap-3 items-center justify-end px-3'>
                                                            <p className='text-[#000] font-[500] text-[15px] text-nowrap'>Total Pages {currentPage}/{components.length || 1}</p>
                                                            <p className='-mt-2'>
                                                                <input
                                                                    type="range"
                                                                    className="cursor-pointer min-w-[200px] h-[3.3px] bg-gray-300 rounded-full appearance-none focus:outline-none [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:w-5 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white pdfeditor-zoom"
                                                                    value={zoom}
                                                                    onChange={(e) => setZoom(e.target.value)}
                                                                    min={48}
                                                                    max={100}
                                                                />
                                                            </p>
                                                            <p className='text-[#000] font-[500] text-[15px]'>{Math.round(((zoom - 48) / (100 - 48)) * 100)}%</p>
                                                            <p className='flex gap-3 ml-[12px] text-[#000]'>
                                                                <button className='hover:bg-gray-200 rounded-md p-1'>
                                                                    <span className="TcNIhA"><span aria-hidden="true" className="NA_Img dkWypw"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm1.5 8V4.5h15V12h-15ZM4 21a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4Zm.5-4.5h4v3h-4v-3Zm11 3h4v-3h-4v3Zm-1.5-3v3h-4v-3h4Z" fill="currentColor"></path></svg></span></span>
                                                                </button>
                                                                <button className='hover:bg-gray-200 rounded-md p-1'>
                                                                    <span className="TcNIhA"><span aria-hidden="true" className="NA_Img dkWypw"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M5.5 4h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 4 9.5v-4C4 4.67 4.67 4 5.5 4zm9 0h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 13 9.5v-4c0-.83.67-1.5 1.5-1.5zm0 9h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4a1.5 1.5 0 0 1-1.5-1.5v-4c0-.83.67-1.5 1.5-1.5zm-9 0h4c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-4A1.5 1.5 0 0 1 4 18.5v-4c0-.83.67-1.5 1.5-1.5zm0-7.5v4h4v-4h-4zm9 0v4h4v-4h-4zm0 9v4h4v-4h-4zm-9 0v4h4v-4h-4z"></path></svg></span></span>
                                                                </button>
                                                                <button className='hover:bg-gray-200 rounded-md p-1'>
                                                                    <span className="TcNIhA"><span aria-hidden="true" className="NA_Img dkWypw"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.636 10.43 18.5 6.565v2.687a.75.75 0 0 0 1.5 0V5.497c0-.966-.53-1.495-1.497-1.495h-3.749a.75.75 0 0 0 0 1.5h2.687l-3.867 3.867a.75.75 0 0 0 1.06 1.061Zm-5.27 3.139-3.867 3.867v-2.688a.75.75 0 0 0-1.5 0v3.75c0 .967.527 1.5 1.493 1.5h3.753a.75.75 0 0 0 0-1.5H6.558l3.869-3.869a.75.75 0 0 0-1.061-1.06Z" fill="currentColor"></path></svg></span></span>
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    currentComponent &&
                                    <div
                                        ref={containerRef}
                                        className="fixed w-[23%] h-[430px] bg-[#f6f7f8] p-3 z-10 mt-0 border-[1px] border-solid rounded-md shadow-lg cursor-move overflow-y-scroll scrollbar-hide"
                                        style={{ top: `${position.top}px`, right: `${position.right}px`, userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUp}
                                    >
                                        <div className='flex gap-3 text-[rgba(0,0,0,0.7)]'>
                                            <FaTools className='text-[25px] cursor-pointer hover:scale-105' />
                                            <span className='cursor-default'><span className='text-sky-400 font-[600]'>T</span>ext customization</span>
                                        </div> <br />

                                        <div className="grid grid-cols-2 gap-x-2 pdfToolContainer">
                                            <div className="mb-4">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                                    </svg>
                                                    Width (%)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={1024}
                                                    value={editOptions.width}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, width: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mb-4">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 4v16m12-16v16" />
                                                    </svg>
                                                    Height (%)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={1449}
                                                    value={editOptions.height}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, height: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mb-4">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2v10m0 0l-4-4m4 4l4-4" />
                                                    </svg>
                                                    Rotation (°)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={180}
                                                    value={editOptions.rotate}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, rotate: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mb-4">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m0 0h-4m4 0h4" />
                                                    </svg>
                                                    Opacity (0 - 1)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={1}
                                                    step="0.1"
                                                    value={editOptions.opacity}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, opacity: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mb-4">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5h18M3 12h18m-7 7h7" />
                                                    </svg>
                                                    Font Size (px)
                                                </label>
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={100}
                                                    value={editOptions.fontSize}
                                                    className="w-full"
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, fontSize: e.target.value }))}
                                                />
                                            </div>

                                            <div className="mb-4 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    Font Family
                                                </label>
                                                <select
                                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, fontFamily: e.target.value }))}
                                                    className="w-full px-4 py-2 h-[44px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5b53e0] transition duration-200 ease-in-out"
                                                >
                                                    <option value="'Arial', sans-serif">Arial</option>
                                                    <option value="'Arial Black', sans-serif">Arial Black</option>
                                                    <option value="'Comic Sans MS', cursive">Comic Sans MS</option>
                                                    <option value="'Courier New', monospace">Courier New</option>
                                                    <option value="'Georgia', serif">Georgia</option>
                                                    <option value="'Times New Roman', serif">Times New Roman</option>
                                                    <option value="'Verdana', sans-serif">Verdana</option>
                                                    <option value="'Tahoma', sans-serif">Tahoma</option>
                                                    <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
                                                    <option value="'Lucida Sans Unicode', sans-serif">Lucida Sans Unicode</option>
                                                    <option value="'Palatino Linotype', serif">Palatino Linotype</option>
                                                    <option value="'Impact', sans-serif">Impact</option>
                                                    <option value="'Segoe UI', sans-serif">Segoe UI</option>
                                                    <option value="'Helvetica Neue', sans-serif">Helvetica Neue</option>
                                                    <option value="'Merryweather', serif">Merryweather</option>
                                                    <option value="'Open Sans', sans-serif">Open Sans</option>
                                                    <option value="'Roboto', sans-serif">Roboto</option>
                                                    <option value="'Lato', sans-serif">Lato</option>
                                                    <option value="'Montserrat', sans-serif">Montserrat</option>
                                                    <option value="'Source Sans Pro', sans-serif">Source Sans Pro</option>
                                                    <option value="'Raleway', sans-serif">Raleway</option>
                                                    <option value="'Oswald', sans-serif">Oswald</option>
                                                    <option value="'PT Sans', sans-serif">PT Sans</option>
                                                    <option value="'Dancing Script', cursive">Dancing Script</option>
                                                    <option value="'Cursive', cursive">Cursive</option>
                                                </select>
                                            </div>

                                            <div className="mb-4 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h18v18H3V3z" />
                                                    </svg>
                                                    Font Color (Hex)
                                                </label>
                                                <div className='flex rounded-md overflow-hidden border border-gray-300 shadow-sm'>
                                                    <input
                                                        type="color"
                                                        value={`${editOptions.color}`}
                                                        className='h-[44px] w-[60px] cursor-pointer p-1 border-r border-gray-300'
                                                        onChange={(e) => setEditOptions(prevState => ({ ...prevState, color: e.target.value }))}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={`${editOptions.color}`}
                                                        className="w-full px-4 py-2 border-none focus:outline-none transition duration-200 ease-in-out text-gray-700"
                                                        placeholder="#000"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    <svg className="w-[15px] h-5 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h18v18H3V3z" />
                                                    </svg>
                                                    Background Color (Hex)
                                                </label>
                                                <div className='flex rounded-md overflow-hidden border border-gray-300 shadow-sm'>
                                                    <input
                                                        type="color"
                                                        className='h-[44px] w-[60px] cursor-pointer p-1 border-r border-gray-300'
                                                        value={`${editOptions.backgroundColor}`}
                                                        onChange={(e) => setEditOptions(prevState => ({ ...prevState, backgroundColor: e.target.value }))}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={`${editOptions.backgroundColor}`}
                                                        className="w-full px-4 py-2 border-none focus:outline-none transition duration-200 ease-in-out text-gray-700"
                                                        placeholder="#000"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-4 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    Text Style
                                                </label>
                                                <div className="flex space-x-2">
                                                    <button
                                                        className={`${editOptions?.bold && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, bold: !prevState.bold }))}
                                                    >
                                                        Bold
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.italic && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, italic: !prevState.italic }))}
                                                    >
                                                        Italic
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.underline && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, underline: !prevState.underline }))}
                                                    >
                                                        Underline
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-4 col-span-2">
                                                <label className="flex items-center text-gray-700 font-medium mb-2">
                                                    Alignments
                                                </label>
                                                <div className="flex space-x-2">
                                                    <button
                                                        className={`${editOptions?.left && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, left: !prevState.left }))}
                                                    >
                                                        Left
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.middle && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, middle: !prevState.middle }))}
                                                    >
                                                        Middle
                                                    </button>
                                                    <button
                                                        className={`${editOptions?.right && "bg-[rgba(0,0,0,0.1)]"} text-black font-medium py-2 px-4 rounded transition duration-200 border border-gray-500`}
                                                        onClick={() => setEditOptions(prevState => ({ ...prevState, right: !prevState.right }))}
                                                    >
                                                        Right
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="flex gap-4 flex-row items-center h-full justify-start">
                                            <div className="flex gap-4 justify-start items-center">
                                                <span
                                                    style={{
                                                        background: currentComponent.color !== "#fff" ? currentComponent.color : 'conic-gradient(from 0deg, green 0%, cyan 25%, #3f76da 50%, magenta 75%, red 100%)',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                    }}
                                                    className="relative w-[30px] h-[30px] rounded-full cursor-pointer"
                                                >
                                                    <label className="absolute top-0 left-0 w-full h-full cursor-pointer">
                                                        <input
                                                            onChange={(e) => setColor(e.target.value)}
                                                            type="color"
                                                            className="invisible w-full h-full cursor-pointer"
                                                        />
                                                    </label>
                                                </span>
                                            </div>

                                            {currentComponent.name === 'svg' &&
                                                <div className="flex gap-2 justify-start items-center">
                                                    <span>Background : </span>
                                                    <label className="w-[30px] h-[30px] cursor-pointer rounded-ms"
                                                        style={{ background: `${currentComponent.backgroundColor && currentComponent.backgroundColor !== '#fff' ? currentComponent.backgroundColor : 'gray'}` }}
                                                        htmlFor="BackgroundColor">
                                                    </label>
                                                    <input onChange={(e) => setBackgroundColor(e.target.value)} type="color" className="invisible" id="BackgroundColor" />
                                                </div>
                                            }

                                            {currentComponent.name === 'main_frame' && image &&
                                                <div>
                                                    <button className="p-[6px] bg-[#f6f7f8] text-[rgba(0,0,0,0.6)] rounded-sm" onClick={removeBackground}>Remove background</button>
                                                </div>
                                            }

                                            {currentComponent.name !== 'main_frame' &&
                                                <div className="flex gap-4 flex-row overflow-x-auto overflow-y-hidden scrollbar-hide">
                                                    <div className="flex gap-1 justify-start items-center">
                                                        <span className="text-sm text-[rgba(0,0,0,0.6)] text-nowrap">Transparency</span>
                                                        <input onChange={opacityHandle}
                                                            className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                            type="number" step={0.1} min={0.1} max={1} value={currentComponent.opacity} />
                                                    </div>

                                                    <div className="flex gap-1 justify-start items-center">
                                                        <span className="text-sm text-[rgba(0,0,0,0.6)]">Layer</span>
                                                        <input onChange={(e) => setzIndex(parseInt(e.target.value))}
                                                            className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                            type="number" step={1} value={currentComponent.z_index} />
                                                    </div>

                                                    {currentComponent.name === 'image' &&
                                                        <div className="flex gap-1 justify-start items-center">
                                                            <span className="text-sm text-[rgba(0,0,0,0.6)]">Radius</span>
                                                            <input onChange={(e) => setRadius(parseInt(e.target.value))}
                                                                className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                                type="number" step={1} value={currentComponent.radius} />
                                                        </div>
                                                    }

                                                    {currentComponent.name === 'text' && <>
                                                        <div className="flex gap-1 justify-start items-center">
                                                            <span className="text-sm text-[rgba(0,0,0,0.6)]">Padding</span>
                                                            <input onChange={(e) => setPadding(parseInt(e.target.value))}
                                                                className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                                type="number" step={1} value={currentComponent.padding} />
                                                        </div>
                                                        <div className="flex gap-1 justify-start items-center">
                                                            <span className="text-sm text-[rgba(0,0,0,0.6)] text-nowrap">Font size</span>
                                                            <input onChange={(e) => setFont(parseInt(e.target.value))}
                                                                className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                                type="number" step={1} value={currentComponent.font} />
                                                        </div>

                                                        <div className="flex gap-1 justify-start items-center">
                                                            <span className="text-sm text-[rgba(0,0,0,0.6)]">Weight</span>
                                                            <input onChange={(e) => setWeight(parseInt(e.target.value))}
                                                                className="w-[70px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                                type="number" step={100} min={100} max={900} value={currentComponent.weight} />
                                                        </div>
                                                        <div className="flex gap-1 justify-start items-center">
                                                            <span className="text-sm text-[rgba(0,0,0,0.6)] text-nowrap">Select a Font</span>
                                                            <select id="fontSelect" className="w-[105px] min-h-[28px] border border-gray-800 bg-transparent outline-none px-2 rounded-md"
                                                                value={selectedFont} onChange={(e) => setSelectedFont(e.target.value)}>
                                                                {fontList.map(font => (
                                                                    <option style={{ 'backgroundColor': 'rgb(37 38 39 / var(--tw-bg-opacity))' }} key={font} value={font}>{font}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="flex relative gap-2 justify-start items-center">
                                                            <input onChange={(e) => setCurrentComponent({ ...currentComponent, title: e.target.value })}
                                                                className="border border-gray-800 bg-transparent outline-none px-2 rounded-md" type="text"
                                                                value={currentComponent.title} />
                                                            <button onClick={() => setText(currentComponent.title)} className="px-4 py-2 bg-[#7731d8] hover:bg-[#612dae] text-xs text-white rounded-sm"> Add</button>
                                                        </div>
                                                    </>}
                                                </div>
                                            }
                                        </div> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
