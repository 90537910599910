import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import Logo from "../Login/Images/logo.png";
import 'react-placeholder/lib/reactPlaceholder.css';

export const PlaceHolder = ({ isLoading = true }) => {
    return (
        <div className="w-full h-screen flex flex-col bg-gray-100">
            <ReactPlaceholder
                type="rect"
                ready={!isLoading}
                showLoadingAnimation={true}
                style={{ width: '100%', height: '60px' }}
            >
                <header className="w-full h-[60px] bg-gradient-to-r from-blue-500 to-purple-500 flex justify-between items-center px-4">
                    <div className="text-white font-bold">Pure Pixel Portrait Studios</div>
                    <div className="flex space-x-4">
                        <button className="bg-white text-purple-700 px-4 py-2 rounded">Order & Print</button>
                        <button className="bg-white text-purple-700 px-4 py-2 rounded">Share</button>
                    </div>
                </header>
            </ReactPlaceholder>

            <div className="w-full h-[calc(100%-70px)] flex">
                <div className="w-[5%] mt-[15px] h-full bg-gray-100 p-3 flex flex-col items-center justify-between">
                    {['Design', 'Elements', 'Draw', 'Upload', 'Text', 'PDF', 'Images', 'Background'].map((text, idx) => (
                        <React.Fragment key={idx}>
                            <ReactPlaceholder
                                key={idx}
                                type="rect"
                                ready={!isLoading}
                                showLoadingAnimation={true}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    marginBottom: '5px',
                                    borderRadius: '8px'
                                }}
                            />
                            <p className='text-[10px] w-full text-center -ml-[8px]'>{text}</p>
                        </React.Fragment>
                    ))}
                </div>

                <div className="w-[95%] h-full bg-gray-100 p-4">
                    <div className='w-full flex justify-center'>
                        <img src={Logo} alt="purePixel-Logo" className='aspect-square w-[120px] h-[100px]' />
                    </div>
                    <ReactPlaceholder
                        type="rect"
                        ready={!isLoading}
                        showLoadingAnimation={true}
                        style={{ width: '80%', height: '70%', marginLeft: '10%', marginBottom: '20px', borderRadius: '6px' }}
                    />
                    <ReactPlaceholder
                        type="rect"
                        ready={!isLoading}
                        showLoadingAnimation={true}
                        style={{ width: '80%', height: '50px', marginLeft: '10%', marginBottom: '10px' }}
                    />
                </div>
            </div>
        </div>
    );
};
