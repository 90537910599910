import React, { useState, useEffect } from 'react';
import LoadingScreen from '../components/loadingScreen';

export default function Background({ myImages, setImage }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <div className='grid grid-cols-2 gap-2'>
                {
                    myImages.map((img, i) => (
                        <div
                            key={i}
                            onClick={() => setImage(`data:image/png;base64,${img.file_base64}`)}
                            className='w-full h-[100px] overflow-hidden rounded-sm cursor-pointer'
                            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#5b5656'; }}
                            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'initial'; }}
                        >
                            <img
                                className='w-full h-full object-fill hover:border hover:border-rgb(253 254 255 / 50%) hover:border-opacity-50 rounded-10'
                                src={`data:image/png;base64,${img.file_base64}`}
                                alt={img.name}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};