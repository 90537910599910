import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import loGoBackground from './Images/Untitled_design__41_-removebg-preview.png'
import PurePixel from '../assets/3pstudio/logo.png'
import backgroundLogo from '../assets/3pstudio/background.jpg'
import SuccessPopup from '../components/SuccessPopupMessage'
import RefusedPopup from '../components/RefusedPopupMessage';
import { EmailNumberAPI, PurchaseYearbookDetailsApi } from '../ServerApi/server'
import { CONNECTION_REFUSED } from '../helper/Helpers'

export default function LoginEmailNumber() {
    const [emailNumber, setEmailNumber] = useState('')
    const { state: currentAccessCode } = useLocation()
    const userAccessCode = currentAccessCode.accessCode;
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const navigate = useNavigate();

    const emailNumberVerification = async () => {
        try {
            const emailNumberApiResponse = await EmailNumberAPI(userAccessCode, emailNumber)
            console.log('\n emailNumberApiResponse', emailNumberApiResponse)
            if (emailNumberApiResponse.status === 200) {
                localStorage.setItem('userId', emailNumberApiResponse.data.user.id)
                localStorage.setItem('role', emailNumberApiResponse.data.user.role)
                if (emailNumberApiResponse.data.user.role === 'admin') {
                    navigate('/dashboard', {
                        state: {
                            role: emailNumberApiResponse.data.user.role,
                            userId: emailNumberApiResponse.data.user.id
                        }
                    })
                } else if (emailNumberApiResponse.data.user.role === 'student') {
                    try {
                        const purchaseYearBookApiResponse = await PurchaseYearbookDetailsApi('purchase', emailNumberApiResponse.data.user.id);
                        if (purchaseYearBookApiResponse.status === 200) {
                            navigate('/purchase_yearbook', { state: purchaseYearBookApiResponse.data.purchase_all_yearbook_details })
                        } else {
                            setPopupMessage(purchaseYearBookApiResponse.response.data.message);
                            setShowPopup(true);
                            setTimeout(() => {
                                setShowPopup(false);
                            }, 2000);
                        }
                    } catch (error) {
                        setPopupRefusedMessage(CONNECTION_REFUSED);
                        setShowPopupRefused(true);
                        setTimeout(() => {
                            setShowPopupRefused(false);
                        }, 2000);
                    }
                }
            } else {
                setPopupMessage(emailNumberApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    return (
        <div class="bg-gray-200">
            <div class="main-content  mt-0">
                <div class="page-header align-items-start min-vh-100" style={{ backgroundImage: `url(${backgroundLogo})` }}>
                    <span class="mask bg-gradient-dark opacity-8"></span>
                    <div class="container my-auto">
                        <div class="row">
                            <div class="col-lg-4 col-md-8 col-12 mx-auto" style={{ marginTop: '90px' }}>
                                <div class="card z-index-0 fadeIn3 fadeInBottom">
                                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                        <div class="shadow-primary border-radius-lg py-3 pe-1" style={{ backgroundColor: '#fea620' }}>
                                            <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">Sign in!</h4>
                                            <center><img src={PurePixel} style={{ width: '150px' }} /></center>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form role="form" class="text-start">
                                            <div class="input-group input-group-outline mb-3">
                                                <input type="text" value={emailNumber} onChange={(e) => setEmailNumber(e.target.value)} class="form-control" placeholder='Enter Email' />
                                            </div>
                                            <div class="text-center">
                                                <button type="button" id='submitEmailButton' class="btn w-100 my-4 mb-2" onClick={emailNumberVerification}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPopup && (
                        <SuccessPopup popupMessage={popupMessage} />
                    )}
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    )
}