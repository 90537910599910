import React, { useState } from 'react'
import { MdOutlineDashboard, MdDesignServices } from "react-icons/md";
import { SlNote } from "react-icons/sl";
import { FaAmazonPay, FaUser } from "react-icons/fa";
import { RiSchoolLine } from "react-icons/ri";
import { FaUsersGear, FaCartShopping } from "react-icons/fa6";
import { GiSecretBook } from "react-icons/gi";
import { BiPurchaseTag } from "react-icons/bi";
import { IoIosCreate } from "react-icons/io";
import dashboardLog from '../assets/img/logo-ct.png'
import { useNavigate } from 'react-router-dom';
import { UserDetailsApi, AdminOrdersDetailsApi, PaymentDetailsApi, PurchaseYearbookDetailsApi, CreateUsersApi } from '../ServerApi/server'
import { CONNECTION_REFUSED } from '../helper/Helpers'
import { Link } from 'react-router-dom';

export default function LeftNavBar() {

    const navigate = useNavigate()

    const createYearbook = () => {
        navigate('/design/edit')
    }

    const [customUserDetails, setCustomUserDetails] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const userId = localStorage.getItem('userId')
    const role = localStorage.getItem('role')

    const userDetailsApi = async () => {

        try {
            const userDetailsApiResponse = await UserDetailsApi();

            if (userDetailsApiResponse.status === 200) {
                navigate('/user_profile_details', { state: { allUserDetails: userDetailsApiResponse.data.custom_user_details } })
            } else {
                setPopupMessage(userDetailsApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const adminAndUserordersApi = async () => {
        try {
            const adminAndUserordersApiResponse = await AdminOrdersDetailsApi(userId, role);

            if (adminAndUserordersApiResponse.status === 200) {
                navigate('/orders', { state: { orderDetails: adminAndUserordersApiResponse.data.all_order_status } })
            } else {
                setPopupMessage(adminAndUserordersApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const paymentdetailsApi = async () => {
        try {
            const paymentdetailsApiResponse = await PaymentDetailsApi(userId, role);
            if (paymentdetailsApiResponse.status === 200) {
                navigate('/payment_history', { state: paymentdetailsApiResponse.data.all_payment_details })
            } else {
                setPopupMessage(paymentdetailsApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const adminDashboard = () => {
        navigate('/dashboard', {
            state: {
                role: role,
                userId: userId
            }
        })
    }

    const designAssets = () => {
        navigate('/design_assets')
    }

    const schools = () => {
        navigate('/schools')
    }

    const purchaseYearBook = async (yearbookOption) => {
        try {
            const purchaseYearBookApiResponse = await PurchaseYearbookDetailsApi(yearbookOption, userId);
            console.log('\n purchaseYearBookApiResponse 111 -------------------->: ', purchaseYearBookApiResponse)
            if (purchaseYearBookApiResponse.status === 200) {
                console.log('\n purchaseYearBookApiResponse', purchaseYearBookApiResponse.data.yearbook_serializer)
                yearbookOption === 'purchase' ? navigate('/purchase_yearbook', { state: purchaseYearBookApiResponse.data.purchase_all_yearbook_details }) : navigate('/saved_yearbook', { state: purchaseYearBookApiResponse.data.yearbook_serializer })

            } else {
                setPopupMessage(purchaseYearBookApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const CreateUser = () => {
        navigate('/create_user')
    }

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark" id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                <a className="navbar-brand m-0" target="_blank" style={{ display: 'flex' }}>
                    <img src={dashboardLog} className="navbar-brand-img h-100" alt="main_logo" />
                    <span className="ms-1 font-weight-bold text-white">Yearbook</span>
                </a>
            </div>
            <hr className="horizontal light mt-0 mb-2" />
            <div className="navbar-collapse w-auto " id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    {role === 'admin' && (
                        <>
                            <li className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">Admin Panel</h6>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white active" onClick={adminDashboard}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        < MdOutlineDashboard style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Dashboard</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={designAssets}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <MdDesignServices style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Design Assets</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={adminAndUserordersApi}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <SlNote style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Orders</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={paymentdetailsApi}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FaAmazonPay style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Payments</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={schools}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <RiSchoolLine style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Schools</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={userDetailsApi}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FaUsersGear style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Users</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={CreateUser}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FaUser style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Create User</span>
                                </a>
                            </li>
                        </>)}
                    {role === 'student' && (
                        <>
                            <li className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">User Panel</h6>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={() => { purchaseYearBook('purchase') }}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <BiPurchaseTag style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Purchase Yearbooks</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={createYearbook}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <IoIosCreate style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Create Yearbook</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={() => { purchaseYearBook('saved') }}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <GiSecretBook style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Saved Yearbooks</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={paymentdetailsApi}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FaAmazonPay style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Payments</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white " onClick={adminAndUserordersApi}>
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <SlNote style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Orders</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link to="/carts" className="nav-link text-white">
                                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FaCartShopping style={{ fontSize: 'large' }} />
                                    </div>
                                    <span className="nav-link-text ms-1">Carts</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </aside>
    )
}