import React from 'react';
import flower_1 from './Fairytale Floral Bouquet Clipart 1.png';
import flower_2 from './Fairytale Floral Bouquet Clipart 2.png';
import flower_3 from './Fairytale Floral Bouquet Clipart 3.png';
import flower_4 from './Fairytale Floral Bouquet Clipart 4.png';

export default function TemplateDesign() {

    return (
        <React.Fragment>
            {
                [flower_1, flower_2, flower_3, flower_4].map((flower, index) => <div className='group w-full h-full rounded-md overflow-hidden bg-[#ffffff12] cursor-pointer border-[1px] border-solid border-[rgba(0,0,0,0.08)]'>
                    <img className='w-full h-full rounded-md overflow-hidden hover:border-[2px] hover:scale-110' src={flower} alt={`design-image-${index}`} loading='lazy' />
                </div>)
            }
        </React.Fragment>
    );

}