import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginAccessCode from '../Login/LoginAccessCode';
import LoginEmailNumber from '../Login/LoginEmailNumber';
import Main from '../pages/Main';
import Dashboard from '../components/Dashboard';
import Users from '../components/Users';
import Orders from '../components/Orders';
import Payments from '../components/Payments';
import DesignAssets from '../components/DesignAssets';
import Schools from '../components/Schools';
import Purchases from '../components/Purchases';
import SavedYearBook from '../components/SavedYearbooks';
import CreateUser from '../components/CreateUser';
import PDFtoPSDConverter from '../components/PdfFileImages';
import PsdUploader from '../components/PSDFiles';
import Carts from '../components/Carts';

export default function ApplicationRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginAccessCode />} />
        <Route path="/email_number_verification" element={<LoginEmailNumber />} />
        <Route path="/design/edit" element={<Main />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user_profile_details" element={<Users />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/payment_history" element={<Payments />} />
        <Route path="/design_assets" element={<DesignAssets />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/purchase_yearbook" element={<Purchases />} />
        <Route path="/saved_yearbook" element={<SavedYearBook />} />
        <Route path="/create_user" element={<CreateUser />} />
        <Route path="/pdffile" element={<PDFtoPSDConverter />} />
        <Route path="/psd_loader" element={<PsdUploader />} />
        <Route path="/carts" element={<Carts />} />
      </Routes>
    </BrowserRouter>
  );
}
