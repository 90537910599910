import React from 'react';
import { TbRefresh } from "react-icons/tb";

export default function Element({ id, info }) {
    return (
        <React.Fragment>
            <div onMouseDown={() => info.resizeElement(id, info)} className='hidden absolute group-hover:block -bottom-[3px] -right-[3px] w-[10px] h-[10px] cursor-nwse-resize bg-[#4f63de] z-[99]'>
            </div>
            <div onMouseDown={() => info.resizeElement(id, info)} className='hidden absolute group-hover:block -top-[3px] -right-[3px] w-[10px] h-[10px] cursor-nwse-resize bg-[#4f63de] z-[99]'>
            </div>
            <div onMouseDown={() => info.resizeElement(id, info)} className='hidden absolute group-hover:block -bottom-[3px] -left-[3px] w-[10px] h-[10px] cursor-nwse-resize bg-[#4f63de] z-[99]'>
            </div>

            <div onMouseDown={() => info.rotateElement(id, info)} className='hidden absolute group-hover:block -top-[2px] -left-[3px] w-[10px] h-[10px] cursor-nwse-resize bg-[#4f63de] z-[99]'>
                <TbRefresh className='absolute -top-2 left-4 bg-slate-50' style={{ fontSize: '15px' }} />
            </div>
        </React.Fragment>
    )
}