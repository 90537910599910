import React, { useState, useEffect } from 'react';
import { UploadImageAPI, GetImages } from '../ServerApi/server';
import { CONNECTION_REFUSED } from '../helper/Helpers';
import SuccessPopup from '../components/SuccessPopupMessage';
import RefusedPopup from '../components/RefusedPopupMessage';
import LoadingScreen from '../components/loadingScreen';
import { FaCloudUploadAlt } from "react-icons/fa";

export default function MyImages({ add_image, PdfUrl }) {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const [myImages, setMyImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchImages = async () => {
        setLoading(true);
        try {
            const getImagesApiResponse = await GetImages('upload', localStorage.getItem('userId'));
            if (getImagesApiResponse.status === 200) {
                setMyImages(getImagesApiResponse.data.design_assets);
            } else {
                setPopupMessage(getImagesApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => setShowPopupRefused(false), 2000);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const handleImageUpload = async (e) => {
        const files = e.target.files;
        if (files.length) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('upload_files', files[i]);
            }
            formData.append('user_id', localStorage.getItem('userId'));
            setLoading(true);
            try {
                const uploadImageFileResponse = await UploadImageAPI(formData);
                if (uploadImageFileResponse.status === 200) {
                    fetchImages();
                } else {
                    setPopupMessage(uploadImageFileResponse.response.data.message);
                    setShowPopup(true);
                    setTimeout(() => setShowPopup(false), 2000);
                }
            } catch (error) {
                setPopupRefusedMessage(CONNECTION_REFUSED);
                setShowPopupRefused(true);
                setTimeout(() => setShowPopupRefused(false), 2000);
            } finally {
                setLoading(false);
            }
        }
    };



    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className='w-full'>
            <div className='w-full h-[40px] flex justify-center items-center bg-[#7731d8] rounded-md hover:bg-[#612dae] text-white mb-3'>
                <label className='text-[15px] font-[500] cursor-pointer w-full h-full flex justify-center items-center gap-2 text-white mt-2' htmlFor="fileUpload">
                    <FaCloudUploadAlt className='text-[18px] font-[500]' /> upload image
                </label>
                <input
                    type='file'
                    id='fileUpload'
                    accept="image/*"
                    className='hidden'
                    multiple
                    onChange={handleImageUpload}
                />
                {loading && <div>Loading...</div>}
            </div>
            <div className='grid grid-cols-2 gap-2'>
                {myImages.map((img, i) => (
                    <div key={i} onClick={() => add_image(`data:image/png;base64,${img.file_base64}`)} className='w-full h-[100px] overflow-hidden rounded-sm cursor-pointer'>
                        <img className='w-full h-full object-fill hover:border hover:border-rgb(253 254 255 / 50%) hover:border-opacity-50 rounded-10' src={`data:image/png;base64,${img.file_base64}`} alt={img.name} />
                    </div>
                ))}
            </div>

            {showPopup && (
                <SuccessPopup popupMessage={popupMessage} />
            )}
            {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
            )}
        </div>
    );
}
