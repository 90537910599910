import React from 'react';
import jsPDF from 'jspdf';
import { Link } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import LoGoImage from '../Login/Images/logo.png';
import { IoMdPrint } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";

export default function Header() {

    // Function to convert specific HTML elements into a PDF file.
    const printImage = async () => {
        const getDiv = document.getElementById('main_design');
        const mainFrameDivs = getDiv.querySelectorAll('div[name="one"]');
        const images = [];

        for (let i = 0; i < mainFrameDivs.length; i++) {
            var frameDiv = mainFrameDivs[i];
            var clonedFrameDiv = frameDiv.cloneNode(true);
            clonedFrameDiv.style.border = 'none';
            document.body.appendChild(clonedFrameDiv);
            try {
                const dataUrl = await htmlToImage.toPng(clonedFrameDiv, {
                    style: {
                        transform: 'scale(1)',
                    },
                });
                images.push(dataUrl);
            } catch (error) {
                console.error('Error generating image:', error);
            }
            document.body.removeChild(clonedFrameDiv);
        }
        const pdf = new jsPDF();
        images.forEach((image, index) => {
            if (index > 0) pdf.addPage();
            pdf.addImage(image, 'PNG', 0, 0, 210, 297);
        });

        // Get the current date and time in YYYY-MM-DD_HH-MM-SS format
        const now = new Date().toISOString().slice(0, 19).replace('T', '_').replace(/:/g, '-');
        pdf.save(`document_${now}.pdf`);
    };

    return (
        <div className='fixed h-[60px] px-3 py-1 w-full z-50 bg-gradient-to-r from-[#00c4cc] to-[#7d2ae8]' >
            <div className='flex justify-between items-center text-gray-300 h-full'>
                <Link to='/design/edit'>
                    <img src={LoGoImage} className='aspect-square w-[100px] h-[50px] -mt-[4px]' alt='pixel-logo' />
                </Link>
                <div className='flex gap-2'>
                    <div className='flex justify-center items-center gap-2 text-gray-300'>
                        <button onClick={printImage} className='px-3 py-[6px] outline-none bg-transparent text-white font-[500] rounded-sm flex gap-1 items-center text-[15px]' style={{ border: '1px solid rgba(255,255,255,0.3)' }}>Order & Print <IoMdPrint className='text-[16px]' /></button>
                    </div>
                    <div className='flex justify-center items-center gap-2 relative group'>
                        <button className='px-3 py-[6px] outline-none bg-white rounded-md text-[#333] font-[500] flex gap-1 items-center text-[15px]' style={{ border: '1px solid rgba(255,255,255,0.3)' }}>
                            <MdOutlineFileUpload className='text-[16px]' />
                            Share
                        </button>
                        <div className="absolute top-full mt-1 -left-3 hidden group-hover:block w-max bg-gray-700 text-white text-xs rounded-lg px-2 py-1 z-10">
                            Share your work
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};