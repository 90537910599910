import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import backgroundLogo from '../assets/3pstudio/background.jpg'
import PurePixel from '../assets/3pstudio/logo.png'
import { AccessCodeAPI } from '../ServerApi/server'
import SuccessPopup from '../components/SuccessPopupMessage'
import RefusedPopup from '../components/RefusedPopupMessage'
import { CONNECTION_REFUSED } from '../helper/Helpers'

export default function LoginAccessCode() {
    const [accessCode, setAccessCode] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const navigate = useNavigate();

    const accessCodeVerification = async () => {
        try {
            const accessCodeApiResponse = await AccessCodeAPI(accessCode)
            if (accessCodeApiResponse.status === 200) {
                navigate('/email_number_verification', { state: { accessCode: accessCodeApiResponse.data.access_code } });
            } else {
                setPopupMessage(accessCodeApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    return (
        <div className="bg-gray-200">
            <div className="main-content mt-0">
                <div
                    className="page-header align-items-start min-vh-100"
                    style={{ backgroundImage: `url(${backgroundLogo})` }}
                >
                    <span className="mask bg-gradient-dark opacity-8"></span>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-12 mx-auto">
                                <div className="hero-text hero-text-left">
                                    <img src={PurePixel} alt="Pure Pixel" />
                                    <h1 className="text-white">
                                        Printed yearbooks. <br />Created together online.
                                    </h1>
                                    <p className="h1-subheading hidden-iphone5 text-white">
                                        Yearbook Machine is a complete service for producing yearbooks and leavers' books.
                                    </p>
                                    <div className="cta-buttons">
                                        <a id='submitButtonStart' className="btn btn-primary text-white" href="">
                                            Start a book
                                        </a>
                                        <a href="" className="btn btn-secondary text-white" style={{ marginLeft: '10px' }}>
                                            Request a sample
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-12 mx-auto" style={{ marginTop: '90px' }}>
                                <div className="card z-index-0 fadeIn3 fadeInBottom">
                                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                        <div className="shadow-primary border-radius-lg py-3 pe-1" style={{ backgroundColor: '#fea620' }} >
                                            <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">Sign in!</h4>
                                            <center>
                                                <img src={PurePixel} style={{ width: '150px' }} alt="Logo" />
                                            </center>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <form role="form" className="text-start">
                                            <div className={`input-group input-group-outline mb-3}`}>
                                                <input type="text" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} className="form-control" placeholder='Enter Access Code'
                                                />
                                            </div>
                                            <div className="text-center">
                                                <button type="button" id='submitButton' className="btn w-100 my-4 mb-2" onClick={accessCodeVerification}>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPopup && (
                        <SuccessPopup popupMessage={popupMessage} />
                    )}
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    )
}

