import React from 'react';
import { toPng } from 'html-to-image';
import PSD from 'psd.js';

const SvgToPsdConverter = () => {
  const svgRef = React.useRef();

  const convertSvgToPsd = async () => {
    try {

      const pngDataUrl = await toPng(svgRef.current);

      const psd = new PSD();

      const image = new Image();
      image.src = pngDataUrl;
      image.onload = () => {
        psd.imageData = image;
        psd.width = image.width;
        psd.height = image.height;

        const psdLayer = psd.addLayer('SVG Layer');
        psdLayer.canvas = image;
        psdLayer.width = image.width;
        psdLayer.height = image.height;


        const psdBuffer = psd.toBuffer();
        const blob = new Blob([psdBuffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'converted_image.psd';
        a.click();
        URL.revokeObjectURL(url);
      };
    } catch (error) {
      console.error('Error converting SVG to PSD:', error);
    }
  };

  return (
    <div>
      <svg ref={svgRef} width="200" height="200" xmlns="http://www.w3.org/2000/svg">
        <circle cx="100" cy="100" r="80" fill="blue" />
      </svg>
      <button onClick={convertSvgToPsd}>Convert to PSD</button>
    </div>
  );
};

export default SvgToPsdConverter;
