import React, { useState, useEffect } from 'react';
import LeftNavBar from '../components/LeftNavBar';
import { useNavigate } from 'react-router-dom';
import { GetCarts } from '../ServerApi/server';

export default function Carts() {
    const [carts, setCarts] = useState([]);
    const navigate = useNavigate();

    const fetchCarts = async () => {
        try {
            const response = await GetCarts();
            setCarts(response);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    };

    useEffect(() => {
        fetchCarts();
    }, []);

    const logoutOption = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('iroled');
        navigate('/');
    };

    return (
        <div>
            <div className="g-sidenav-show bg-gray-200" style={{ height: '100vh' }}>
                <LeftNavBar />
                <div className="main-content position-relative max-height-vh-800 h-100 border-radius-lg">
                    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl">
                        <div className="container-fluid py-1 px-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                    <li className="breadcrumb-item text-sm">
                                        <button className="opacity-5 text-dark" onClick={() => { }}>
                                            Pages
                                        </button>
                                    </li>
                                    <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
                                        Carts
                                    </li>
                                </ol>
                                <h6 className="font-weight-bolder mb-0">Carts</h6>
                            </nav>
                        </div>
                        <div className='logoutOption'>
                            <h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6>
                        </div>
                    </nav>
                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="card my-4">
                                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                        <div className="shadow-primary border-radius-lg pt-4 pb-3" style={{ backgroundColor: 'rgb(254, 166, 32)' }}>
                                            <h6 className="text-white text-capitalize ps-3">Cart Items</h6>
                                        </div>
                                    </div>
                                    <div className="card-body px-0 pb-2">
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Yearbook</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Quantity Amount</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Amount</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {carts.map((cart, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">
                                                                    <div>
                                                                        <img src={cart.yearbook.front_page} className="avatar avatar-sm me-3 border-radius-lg" alt="Yearbook" />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{cart.quantity}</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">${cart.quantity_amount}</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">${cart.total_amount}</p>
                                                            </td>
                                                            <td>
                                                                <span className="text-secondary text-xs font-weight-bold">{new Date(cart.date).toLocaleDateString()}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
