import React, { useState } from 'react'
import LeftNavBar from './LeftNavBar'
import { CreateUsersApi } from '../ServerApi/server'
import { CONNECTION_REFUSED } from '../helper/Helpers'
import { useNavigate } from 'react-router-dom'

export default function CreateUser() {

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const [errors, setErrors] = useState({});


    const createNewUsersApi = async () => {

        const validateForm = () => {
            const newErrors = {};
            if (!name) newErrors.name = "Name is required";
            if (!email) newErrors.email = "Email is required";
            if (!phone) newErrors.phone = "Phone number is required";
            if (!role) newErrors.role = "Role is required";
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        };

        validateForm();

        try {
            const createNewUsersApiResponse = await CreateUsersApi(name, email, phone, role)
            if (createNewUsersApiResponse.status === 200) {
                console.log('createNewUsersApiResponse 22222', createNewUsersApiResponse);
                setName('');
                setPhone('');
                setEmail('');
                setRole('');
            } else {
                setPopupMessage(createNewUsersApiResponse.response.data.message);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(CONNECTION_REFUSED);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const logoutOption = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('iroled');
        navigate('/');
    }

    return (
        <div class="g-sidenav-show  bg-gray-200" style={{ height: '100vh' }}>
            <LeftNavBar />
            <div class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
                    <div class="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
                                <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Create User</li>
                            </ol>
                            <h6 class="font-weight-bolder mb-0">Create User</h6>
                        </nav>
                        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">

                            <ul class="navbar-nav  justify-content-end">

                                <li class="nav-item d-flex align-items-center">
                                    <a href="pages/sign-in.html" class="nav-link text-body font-weight-bold px-0">
                                        <i class="fa fa-user me-sm-1"></i>
                                        <span class="d-sm-inline d-none">Sign In</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoutOption'><h6 className="font-weight-bolder mb-0" onClick={logoutOption} style={{ padding: '10px' }}>Logout</h6></div>
                </nav>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
                            <div class="card card-plain">
                                <div class="card-header">
                                    <h4 class="font-weight-bolder">Add User</h4>

                                </div>
                                <div class="card-body">
                                    <form role="form">
                                        <label class="form-label">Name</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} class="form-control" required />

                                        </div>
                                        {errors.name && <div className="text-danger">{errors.name}</div>}
                                        <label class="form-label">Gmail</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} class="form-control" required />
                                        </div>
                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                        <label class="form-label">Phone</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <input type="number" value={phone} onChange={(e) => { setPhone(e.target.value) }} class="form-control" required />
                                        </div>
                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                        <label class="form-label">Role</label>
                                        <div class="input-group input-group-outline mb-3">
                                            <select class="form-control" value={role} onChange={(e) => { setRole(e.target.value) }}>
                                                <option>--Select--</option>
                                                <option>Admin</option>
                                                <option>Manager</option>
                                                <option>Student</option>
                                            </select>
                                        </div>
                                        {errors.role && <div className="text-danger">{errors.role}</div>}
                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="text-center">
                                                    <button type="button" id="saveNewCreateUser" class="btn btn-lg btn-lg w-100 mt-4 mb-0" onClick={createNewUsersApi}>Save</button>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
                                                <div class="text-center">
                                                    <button type="button" id="saveAnotherNewCreateUser" class="btn btn-lg btn-lg w-100 mt-4 mb-0">Save and Add Another</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}