import React, { useState, useEffect } from 'react';
import LoadingScreen from '../components/loadingScreen';

export default function Image({ add_image, add_element, element, myImages }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, element === 'element' ? 1000 : 1000);

        return () => clearTimeout(timer);
    }, [element]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className='grid grid-cols-2 gap-2' style={{ gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}>
            {myImages.map((img, i) => {
                const isSVG = img.asset_type === 'svg';
                const base64Prefix = isSVG ? 'data:image/svg+xml;base64,' : 'data:image/png;base64,';
                const handleClick = element === 'element'
                    ? () => add_element(`${base64Prefix}${img.file_base64}`)
                    : () => add_image(`${base64Prefix}${img.file_base64}`);
                return (
                    <div
                        key={i}
                        onClick={handleClick}
                        className='w-full h-[100px] overflow-hidden rounded-sm cursor-pointer'
                        onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#5b5656'; }}
                        onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'initial'; }}
                    >
                        <img
                            className='w-full h-full object-fill hover:border hover:border-rgb(253 254 255 / 50%) hover:border-opacity-50 rounded-10'
                            src={`${base64Prefix}${img.file_base64}`}
                            alt={img.name}
                        />
                    </div>
                );
            })}
        </div>
    );
}
