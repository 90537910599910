import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.mjs`;

export default function PdfSidebar({ showPdfUrl }) {
    const [pdfList, setPdfList] = useState([]);
    const [previews, setPreviews] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await fetch('https://yearbook.3pstudio.us/api/accounts/list-pdfs/');
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
				console.log('data 1111111', data)
                if (Array.isArray(data) && data.length > 0) {
					console.log('data 2222222222', data)
                    setPdfList(data);
                    await loadPreviews(data);
                }
            } catch (error) {
                setError('Failed to load PDFs. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchPdfs();
    }, []);

    const loadPreviews = async (pdfs) => {
        const newPreviews = {};
        for (const pdf of pdfs) {
            if (pdf.file) {
                const preview = await renderPdfPreview(pdf.file);
                newPreviews[pdf.file] = preview;
            }
        }
        setPreviews(newPreviews);
    };

    const renderPdfPreview = async (pdfUrl) => {
        if (!pdfUrl) {
            return '';
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        try {
            const fullUrl = `https://yearbook.3pstudio.us${pdfUrl}`;
            const pdf = await pdfjsLib.getDocument(fullUrl).promise;
            const page = await pdf.getPage(1);
            const scale = 0.7;
            const viewport = page.getViewport({ scale: scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({
                canvasContext: ctx,
                viewport: viewport
            }).promise;

            return canvas.toDataURL();
        } catch (error) {
            return '';
        }
    };

    return (
        <React.Fragment>
            {loading ? (
                <div className="loader">Loading PDFs...</div>
            ) : error ? (
                <div className="error">{error}</div>
            ) : pdfList.length > 0 ? (
                <ul className="pdf-list">
                    {pdfList.map((pdf, index) => (
                        <li key={index} className="pdf-item min-w-[300px] max-w-[300px] min-h-[200px] max-h-[200px] p-2 flex items-center justify-center" onClick={() => showPdfUrl({ url: `https://yearbook.3pstudio.us${pdf.file}`, pages: pdf.pages })}>
                            <p className="text-[12px] text-wrap font-[500] text-center text-[rgba(0,0,0,0.6)]" title={pdf.name || `PDF ${index + 1}`}>
                                {pdf?.file?.split("/")[3] || `PDF ${index + 1}`}
                            </p>
                            <img
                                src={previews[pdf.file] || ''}
                                alt={`Preview of ${pdf.name || `PDF ${index + 1}`}`}
                                className="max-h-[150px]"
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="no-pdfs-message w-[150%] text-center">No PDFs found.</p>
            )}
        </React.Fragment>
    );
}
