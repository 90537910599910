import React, { useState } from 'react';
import { FaCloudUploadAlt } from "react-icons/fa";
import PSD from 'psd.js';

const PsdUploader = () => {
  const [psdFile, setPsdFile] = useState(null);
  const [psdLayers, setPsdLayers] = useState([]);
  const [psdImageData, setPsdImageData] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [showLayers, setShowLayers] = useState(false);

  const handlePsdUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        try {
          const psd = new PSD(new Uint8Array(buffer));
          await psd.parse();
          setPsdFile(psd);
          const layers = psd.tree().descendants();
          setPsdLayers(layers);

          const mainImageBuffer = await psd.image.toPng();
          setMainImage(mainImageBuffer.src);

          const images = await Promise.all(
            layers.map(async (layer) => {
              if (!layer.isGroup() && layer.visible) {
                const layerBuffer = await layer.toPng();
                return { name: layer.name, image: layerBuffer.src, info: layer.export() };
              }
              return null;
            })
          );
          setPsdImageData(images.filter((img) => img !== null));
        } catch (error) { }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const renderLayerInfo = (layer) => {
    const imageData = psdImageData.find(img => img.name === layer.name);
    return (
      <div key={layer.id}>
        <h5>{layer.name}</h5>
        <p>Layer Info: {JSON.stringify(layer.export(), null, 2)}</p>
        {imageData ? (
          <img
            src={imageData.image}
            alt={layer.name}
            style={{ maxWidth: '100%' }}
          />
        ) : (
          <p>Image data not available for this layer</p>
        )}
      </div>
    );
  };

  return (
    <div className='mt-3'>
      <div className='relative w-[200%] h-[40px] bg-[#7731d8] rounded-md hover:bg-[#612dae] transition-all duration-150 ease-in-out'>
        <p className='text-[15px] font-[500] text-white text-center absolute top-0 left-0 w-full h-full -z-0 flex gap-2 items-center justify-center'> <FaCloudUploadAlt className='text-[18px] font-[500]' /> upload .psd</p>
        <input type="file" className='absolute top-0 left-0 w-full h-full cursor-pointer opacity-0' accept=".psd" onChange={handlePsdUpload} />
      </div>
      {mainImage && (
        <div>
          <h3>PSD File:</h3>
          <img src={mainImage} alt="Main PSD Image" style={{ maxWidth: '100%' }} />
        </div>
      )}
      {psdFile && (
        <button onClick={() => setShowLayers(!showLayers)}>
          {showLayers ? 'Hide Layers' : 'Show Layers'}
        </button>
      )}
      {showLayers && psdLayers.length > 0 && (
        <div>
          <h3>PSD Layers:</h3>
          {psdLayers.map((layer) => !layer.isGroup() && renderLayerInfo(layer))}
        </div>
      )}
    </div>
  );
};

export default PsdUploader;