import axios from 'axios';

const serverUrl = 'https://yearbook.3pstudio.us';

export const AccessCodeAPI = async (access_code) => {
    let url = `${serverUrl}/api/validate_access_code`;
    const payload = { access_code };

    try {
        const accessCodeApiResponse = await axios.post(url, payload);
        return accessCodeApiResponse;
    } catch (error) {
        return error;
    }
};

export const EmailNumberAPI = async (access_code, email_number) => {
    let url = `${serverUrl}/api/email_number`;
    const payload = { access_code, email_number };

    try {
        const emailNumberApiResponse = await axios.post(url, payload);
        return emailNumberApiResponse;
    } catch (error) {
        return error;
    }
};

export const UploadImageAPI = async (formDataFiles) => {
    let url = `${serverUrl}/api/user_panel/upload_image`;
    const header = { header: { 'Content-Type': 'multipart/form-data' } };

    try {
        const uploadImageApiResponse = await axios.post(url, formDataFiles, header);
        return uploadImageApiResponse;
    } catch (error) {
        return error;
    }
};

export const GetImages = async (asset_type, user_id) => {
    let url = `${serverUrl}/api/user_panel/get_images`;
    const payload = { asset_type, user_id };

    try {
        const uploadImageApiResponse = await axios.post(url, payload);
        return uploadImageApiResponse;
    } catch (error) {
        return error;
    }
};

export const GetCarts = async () => {
    const url = `${serverUrl}/api/accounts/carts/`;

    try {
        const response = await axios.get(url);
        return response.status === 200 ? response.data : response;
    } catch (error) {
        return [];
    }
};

export const DashboardDetailsAPI = async () => {
    let url = `${serverUrl}/api/accounts/order_statistics/`;

    try {
        const dashboardDetailsApiResponse = await axios.get(url);
        return dashboardDetailsApiResponse;
    } catch (error) {
        return error;
    }
};

export const UserDetailsApi = async () => {
    let url = `${serverUrl}/api/accounts/user_details/`;

    try {
        const userDetailsApiResponse = await axios.get(url);
        return userDetailsApiResponse;
    } catch (error) {
        return error;
    }
};

export const AdminOrdersDetailsApi = async (user_id, role) => {
    let url = `${serverUrl}/api/accounts/current_dispatched_orders/`;
    const payload = { user_id, role };

    try {
        const adminOrdersApiResponse = await axios.post(url, payload);
        return adminOrdersApiResponse;
    } catch (error) {
        return error;
    }
};

export const PaymentDetailsApi = async (user_id, role) => {
    let url = `${serverUrl}/api/accounts/payment_details/`;
    const payload = { user_id, role };

    try {
        const paymentDetailsApiResponse = await axios.post(url, payload);
        return paymentDetailsApiResponse;
    } catch (error) {
        return error;
    }
};

export const PurchaseYearbookDetailsApi = async (yearbook_option, user_id) => {
    let url = `${serverUrl}/api/accounts/purchase_saved_yearbook/`;
    const payload = { yearbook_option, user_id };

    try {
        const purchaseYearbookDetailsApiResponse = await axios.post(url, payload);
        return purchaseYearbookDetailsApiResponse;
    } catch (error) {
        return error;
    }
};

export const CreateUsersApi = async (name, email, phone, role) => {
    let url = `${serverUrl}/api/create_users`;
    const payload = { name, email, phone, role };

    try {
        const createUserApiResponse = await axios.post(url, payload);
        return createUserApiResponse;
    } catch (error) {
        return error;
    }
};
