import React, { useState, useRef, useEffect } from 'react';
import { BsTrash } from 'react-icons/bs';
import Element from './Element';
import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa';
import { getPdfPage } from '../helper/Helpers';

export default function CreateComponent({ info, current_component, removeComponent, setText, setMainFrameId,
    setImage, allComponents, setCurrentComponent, setComponents, components, mainFrameId,
    drawStateColor, drawOption, moveElement, resizeElement, rotateElement, setDrawOption, drawFontWeight, pdfPages, pageIndex, zoom, getElementStyles, setElementStyles }) {

    const [isEditable, setIsEditable] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [pdfPageScale, setPdfPageScale] = useState(0.3);
    const [selectedElement, setSelectedElement] = useState(null);
    const textRef = useRef(null);
    var html = '';

    const handleBoldClick = () => {
        if (selectedText) {
            document.execCommand('bold');
            setShowPopup(false);
            updateText();
        }
    };

    const handleItalicClick = () => {
        if (selectedText) {
            document.execCommand('italic');
            setShowPopup(false);
            updateText();
        }
    };

    const handleUnderlineClick = () => {
        if (selectedText) {
            document.execCommand('underline');
            setShowPopup(false);
            updateText();
        }
    };

    const handleTextSelection = () => {
        const selection = window.getSelection();
        if (selection.toString().length > 0) {
            const selectedText = selection.toString();
            setSelectedText(selectedText);
            setShowPopup(true);
            setIsEditable(true);
        } else {
            setShowPopup(false);
            setIsEditable(false);
        }
    };

    const updateText = () => {
        if (textRef.current) {
            const updatedText = textRef.current.innerHTML;
            setText(info.id, updatedText);
            setShowPopup(false);
            setIsEditable(false);
        }
    };

    const handleMainFrame = (info) => {
        if (info.name === 'main_frame') {
            setImage(info.image);
            setMainFrameId(info.id);
            setCurrentComponent(info);
        }
    };

    const handleComponentClick = (e, subInfo) => {
        e.stopPropagation();
        setCurrentComponent(subInfo);
    };

    const addSvgComponent = (pngData, minX, minY, width, height, lineWidth) => {
        const id = Date.now();

        const style = {
            id: id,
            mainFrameId: mainFrameId,
            name: 'draw',
            left: minX,
            top: minY,
            opacity: 1,
            width: width,
            height: height,
            z_index: 2,
            color: drawStateColor,
            lineWidth: lineWidth,
            imageData: pngData,
            setCurrentComponent: (a) => setCurrentComponent(a),
            moveElement,
            resizeElement,
            rotateElement
        };

        setCurrentComponent(style);
        setComponents([...components, style]);
    };

    const canvasRef = useRef(null);
    const pathDataRef = useRef('');
    const lastX = useRef(0);
    const lastY = useRef(0);
    const drawingInProgress = useRef(false);
    const minX = useRef(null);
    const minY = useRef(null);
    const maxX = useRef(null);
    const maxY = useRef(null);

    const startDrawing = (e) => {
        if (canvasRef.current) {
            drawingInProgress.current = true;
            const { offsetX, offsetY } = e.nativeEvent;
            lastX.current = offsetX;
            lastY.current = offsetY;
            minX.current = offsetX;
            minY.current = offsetY;
            maxX.current = offsetX;
            maxY.current = offsetX;
            pathDataRef.current = `M ${offsetX} ${offsetY}`;
        }
    };

    const draw = (e) => {
        if (canvasRef.current && drawingInProgress.current) {
            const { offsetX, offsetY } = e.nativeEvent;
            const ctx = canvasRef.current.getContext('2d');
            ctx.beginPath();
            ctx.moveTo(lastX.current, lastY.current);
            ctx.lineTo(offsetX, offsetY);
            ctx.strokeStyle = drawStateColor;
            ctx.lineWidth = drawFontWeight;
            ctx.stroke();
            lastX.current = offsetX;
            lastY.current = offsetY;
            pathDataRef.current += ` L ${offsetX} ${offsetY}`;
        }
    };

    const endDrawing = () => {
        if (drawingInProgress.current) {
            drawingInProgress.current = false;
            setDrawOption(false)
            saveDrawing();
        }
    };

    const saveDrawing = () => {
        const svgData = pathDataRef.current;
        const width = canvasRef.current.width;
        const height = canvasRef.current.height;
        var lineWidth = drawFontWeight;
        addSvgComponent(svgData, minX.current, minY.current, width, height, lineWidth);
    };

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.lineWidth = drawFontWeight;
            ctx.strokeStyle = drawStateColor;
        }
    }, [drawStateColor]);

    useEffect(() => {

        const renderPage = async () => {
            const pageElement = document.querySelector(`.pdfPage-${pageIndex}`);
            if (pageElement) {
                const page = await getPdfPage(pageIndex, pdfPages);
                pageElement.innerHTML = page;
            }
        };

        if (pdfPages && pageIndex) {
            renderPage();
        }
    }, [pdfPages, pageIndex]);

    useEffect(() => {
        if (zoom) {
            let currentScale;
            if (zoom <= 49) {
                currentScale = 0.31;
            } else if (zoom > 49 && zoom <= 51) {
                currentScale = 0.32;
            } else if (zoom > 51 && zoom <= 53) {
                currentScale = 0.33;
            } else if (zoom > 53 && zoom <= 54) {
                currentScale = 0.34;
            } else if (zoom > 54 && zoom <= 56) {
                currentScale = 0.35;
            } else if (zoom > 56 && zoom <= 58) {
                currentScale = 0.36;
            } else if (zoom > 58 && zoom <= 60) {
                currentScale = 0.37;
            } else if (zoom > 59 && zoom <= 60) {
                currentScale = 0.38;
            } else if (zoom > 60 && zoom <= 61) {
                currentScale = 0.39;
            } else if (zoom > 61 && zoom <= 63) {
                currentScale = 0.40;
            } else if (zoom > 63 && zoom <= 65) {
                currentScale = 0.41;
            } else if (zoom > 65 && zoom <= 67) {
                currentScale = 0.42;
            } else if (zoom > 67 && zoom <= 69) {
                currentScale = 0.43;
            } else if (zoom > 69 && zoom <= 71) {
                currentScale = 0.44;
            } else if (zoom > 71 && zoom <= 75) {
                currentScale = 0.46;
            } else if (zoom > 75 && zoom <= 79) {
                currentScale = 0.49;
            } else if (zoom > 79 && zoom <= 81) {
                currentScale = 0.51;
            } else if (zoom > 81 && zoom <= 83) {
                currentScale = 0.52;
            } else if (zoom > 83 && zoom <= 93) {
                currentScale = 0.55;
            } else if (zoom > 93 && zoom <= 99) {
                currentScale = 0.59;
            } else {
                currentScale = 0.63;
            }

            setPdfPageScale(currentScale);
        }
    }, [zoom]);

    useEffect(() => {
        if (selectedElement && setElementStyles) {
            selectedElement.style.width = `${setElementStyles?.width}px`;
            selectedElement.style.height = `${setElementStyles?.height}px`;
            selectedElement.style.transform = `rotate(${setElementStyles?.rotate}deg)`;
            selectedElement.style.opacity = setElementStyles?.opacity;
            selectedElement.style.fontSize = `${setElementStyles.fontSize}px`;
            selectedElement.style.fontFamily = setElementStyles.fontFamily;
            selectedElement.style.background = setElementStyles.backgroundColor;
            selectedElement.style.color = setElementStyles.color;
            selectedElement.style.fontWeight = setElementStyles.bold ? `bold` : 'normal';
        }
    }, [selectedElement, setElementStyles]);

    let currentElement = null;
    let isDragging = false;
    let isClicked = true;
    let isElementActive = false;

    const handlePdfPageController = (e) => {
        const element = e.target;
        const RootElement = document.getElementById('pdfContainer');
        const ElementParent = element.parentNode;
        if (element && !element.className.includes('page') && (ElementParent.className === 'image' || ElementParent.className === 'vector' || element.innerText.trim() !== "")) {
            if (e.type === 'mouseover' && !isElementActive) {
                element.style.cursor = 'all-scroll';
                element.style.border = '4px solid rgb(139 61 255)';

                const findRootElements = RootElement.querySelectorAll('span, .vector, img');
                const borderedSpans = Array.from(findRootElements).filter(span =>
                    span.style.border !== '' && !span.hasAttribute('data-id')
                );

                if (borderedSpans.length > 1) {
                    borderedSpans.forEach(span => {
                        span.style.border = '';
                    });
                }

            }

            if (e.type === "click" && !isElementActive) {
                const findRootElements = RootElement.querySelectorAll('span, .vector, img');
                const borderedSpans = Array.from(findRootElements).filter(span => span.style.border !== '');

                if (borderedSpans.length > 1) {
                    borderedSpans.forEach(span => {
                        span.style.border = '';
                    });
                }

                isClicked = true;
                element.style.border = '4px solid rgb(139 61 255)';
                element.style.border = '3px solid #6366F1';
                element.style.outline = 'none';
                element.style.borderRadius = '5px';
                element.style.padding = '5px';
                element.setAttribute('data-id', Math.random(Math.floor() * 10));

                setSelectedElement(element);

                // parse selected element styles
                const width = getComputedStyle(element, null).getPropertyValue('width').replace('px', '');
                const height = getComputedStyle(element, null).getPropertyValue('height').replace('px', '');
                const transform = getComputedStyle(element, null).getPropertyValue('transform');
                const opacity = getComputedStyle(element, null).getPropertyValue('opacity');
                const fontSize = getComputedStyle(element, null).getPropertyValue('fontSize');
                const fontFamily = getComputedStyle(element, null).getPropertyValue('font-family');
                const backgroundColor = getComputedStyle(element, null).getPropertyValue('background-color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const color = getComputedStyle(element, null).getPropertyValue('color').match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0')).join('');
                const bold = getComputedStyle(element, null).getPropertyValue('font-weight');

                let rotate = 0;
                if (transform !== 'none') {
                    const values = transform.split('(')[1].split(')')[0].split(',');
                    const a = values[0];
                    const b = values[1];
                    rotate = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                }

                const data = { width, height, rotate, opacity, fontSize, fontFamily, backgroundColor: `#${backgroundColor}`, color: `#${color}`, bold: Boolean(bold) };
                getElementStyles(data);

                if (element.innerText.trim() !== "") {
                    element.setAttribute("contentEditable", "true");
                    element.style.cursor = "text";
                    element.focus();
                }
            }

            if (e.type === 'mouseout' && !isElementActive) {
                if (!isClicked) {
                    element.style.border = '';
                    if (element.hasAttribute('data-id')) element.removeAttribute('data-id');
                } else {
                    isClicked = false;
                }
                element.style.padding = '0';
                element.style.borderRadius = '0';
            }

            if (e.type === 'mousedown') {
                e.preventDefault();
                isDragging = true;
                isElementActive = true;
                currentElement = element;
                currentElement.style.cursor = 'all-scroll';
            }

        }

        if (currentElement && e.type === 'mouseup') {
            isDragging = false;
            currentElement.style.cursor = 'all-scroll';
            if (currentElement) {
                currentElement.style.cursor = 'all-scroll';
            }
            currentElement = null;
            isElementActive = false;
        }

    };

    const handleMouseMove = (e) => {
        if (isDragging && currentElement) {

            if (currentElement.tagName === "IMG") {
                const parentNode = currentElement.parentNode;
                let elementWidth = null, elementHeight = null;
                if (parentNode) {
                    parentNode.removeAttribute('style');
                    currentElement = parentNode;
                    elementWidth = getComputedStyle(currentElement, null).getPropertyValue('width');
                    elementHeight = getComputedStyle(currentElement, null).getPropertyValue('height');
                }
                currentElement.style.position = 'absolute';
                currentElement.style.width = elementWidth;
                currentElement.style.height = elementHeight;
            }

            const targetElement = e.currentTarget;
            const parentElement = targetElement.parentNode;

            const totalWidth = parentElement.offsetWidth;
            const totalHeight = parentElement.offsetHeight;

            const mouseX = e.clientX - parentElement.getBoundingClientRect().left;
            const mouseY = e.clientY - parentElement.getBoundingClientRect().top;

            const mouseXPercentage = (mouseX / totalWidth) * 100;
            const mouseYPercentage = (mouseY / totalHeight) * 100;

            currentElement.style.left = `${mouseXPercentage - 15}%`;
            currentElement.style.top = `${mouseYPercentage - 2}%`;

        }

    };

    if (info.name === 'main_frame') {
        html = (
            <React.Fragment>
                <div
                    // id={info.id}
                    id='pdfContainer'
                    name={info.name}
                    onClick={() => handleMainFrame(info)}
                    className={`hover:border-[2px] hover:border-indigo-500 shadow-lg`}
                    style={{
                        background: info.color,
                        zIndex: info.z_index,
                        padding: '15px',
                        boxSizing: 'border-box',
                        marginTop: '13px',
                        position: 'relative',
                        width: `${zoom * 6.5}px`,
                        height: `${zoom * 9}px`,
                        overflow: 'hidden'
                    }}
                >
                    <div
                        className={`pdfPage-${pageIndex} absolute top-0 left-0 flex justify-center items-center z-[999]`} style={{ transform: `scale(${pdfPageScale})` }}
                        onMouseOver={handlePdfPageController}
                        onMouseOut={handlePdfPageController}
                        onMouseDown={handlePdfPageController}
                        onClick={handlePdfPageController}
                        onMouseUp={handlePdfPageController}
                        onMouseMove={handleMouseMove}
                    ></div>
                    {/* {
                        current_component.id === info.id && info.pageCount !== 0 && <div onClick={() => removeComponent(info)}
                            className='px-3 py-2 bg-white absolute group-hover:block cursor-pointer rounded-md'>
                            <BsTrash />
                        </div>
                    } */}
                    {/* <div name='sub_main_frame'
                        className='w-full h-full'
                        style={{
                            boxSizing: 'border-box',
                            width: '270px',
                            height: '337px'
                        }}>

                        <div name='sub_main_frame_elements'
                            style={{
                                marginLeft: '14px',
                                width: '240px',
                                height: '311px',
                                marginTop: '11px',

                            }}>
                            {drawOption && (
                                <canvas
                                    ref={canvasRef}
                                    width={338 + 'px'}
                                    height={409 + 'px'}
                                    onMouseDown={startDrawing}
                                    onMouseMove={draw}
                                    onMouseUp={endDrawing}
                                    onMouseLeave={endDrawing}
                                    background={info.image}
                                    style={{
                                        position: 'absolute',
                                        cursor: 'crosshair',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}
                                />
                            )}
                            <div name='one' style={{ height: '409px' }}>
                                {info.image && (
                                    <img
                                        className='w-full h-full'
                                        src={info.image}
                                        alt="image"
                                    />
                                )}
                                {
                                    allComponents.map((subInfo, i) => (subInfo.name !== 'main_frame' && subInfo.mainFrameId === info.id && (
                                        < >
                                            {subInfo.name === 'shape' && subInfo.type === 'rect' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    width: subInfo.width + 'px',
                                                    height: subInfo.height + 'px',
                                                    background: subInfo.color,
                                                    opacity: subInfo.opacity,
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)'

                                                }}
                                                    className='absolute group hover:border-[1px] hover:border-indigo-500'
                                                >
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                            <BsTrash />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {subInfo.name === 'shape' && subInfo.type === 'circle' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)'

                                                }}
                                                    className='absolute group hover:border-[2px] hover:border-indigo-500'
                                                >
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <div id={`${subInfo.id}c`} className='rounded-full' style={{
                                                        width: subInfo.width + 'px',
                                                        height: subInfo.width + 'px',
                                                        background: subInfo.color,
                                                        opacity: subInfo.opacity
                                                    }}>
                                                    </div>
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                            <BsTrash />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {subInfo.name === 'shape' && subInfo.type === 'trangle' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)'

                                                }}
                                                    className='absolute group hover:border-[2px] hover:border-indigo-500'
                                                >
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <div id={`${subInfo.id}t`} style={{
                                                        width: subInfo.width + 'px',
                                                        height: subInfo.height + 'px',
                                                        background: subInfo.color,
                                                        opacity: subInfo.opacity,
                                                        clipPath: 'polygon(50% 0, 100% 100%, 0 100%)'
                                                    }}>
                                                    </div>
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                            <BsTrash />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {subInfo.name === 'text' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)',
                                                    padding: subInfo.padding + 'px',
                                                    color: subInfo.color,
                                                    opacity: subInfo.opacity,
                                                }}
                                                    className='absolute group hover:border-[2px] hover:border-indigo-500'
                                                >
                                                    {showPopup && (
                                                        <div style={{
                                                            gap: '15px',
                                                            display: 'flex',
                                                            backgroundColor: '#fbfbfb',
                                                            border: '1px solid #cbcbcb',
                                                            marginLeft: '55px',
                                                            borderRadius: '8px',
                                                            padding: '4px',
                                                            width: '138px'
                                                        }}>
                                                            <button className='text-bold'
                                                                onClick={handleBoldClick}><FaBold /></button>
                                                            <button className='text-bold'
                                                                onClick={handleItalicClick}><FaItalic /></button>
                                                            <button className='text-bold'
                                                                onClick={handleUnderlineClick}><FaUnderline /></button>
                                                        </div>
                                                    )}
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <h2 ref={textRef} onMouseDown={() => subInfo.moveElement(`${subInfo.id}`, subInfo)} style={{ fontSize: subInfo.font + 'px', fontWeight: subInfo.weight, outline: 'none', fontFamily: subInfo.fontFamily, cursor: 'all-scroll' }}

                                                        className='w-full h-full'
                                                        onMouseUp={handleTextSelection}
                                                        contentEditable={isEditable}
                                                        dangerouslySetInnerHTML={{ __html: subInfo.title }}
                                                        onInput={updateText} ></h2>
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='p-1 bg-slate-50 absolute left-10 -top-3 hidden group-hover:block cursor-pointer'>
                                                            <BsTrash style={{ fontSize: '13px' }} />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            {subInfo.name === 'image' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)',
                                                    opacity: subInfo.opacity
                                                }}
                                                    className='absolute group hover:border-[2px] hover:border-indigo-500'
                                                >
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <div className='overflow-hidden' onMouseDown={() => subInfo.moveElement(`${subInfo.id}`, subInfo)}
                                                        id={`${subInfo.id}img`} style={{
                                                            cursor: 'all-scroll',
                                                            width: subInfo.width + 'px',
                                                            height: subInfo.height + 'px',
                                                            borderRadius: `${subInfo.radius}%`
                                                        }}>
                                                        <img id={`${subInfo.id}img`} style={{ background: subInfo.color }} className='w-full h-full' src={subInfo.image} alt='image' />
                                                    </div>
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                            <BsTrash />
                                                        </div>
                                                    }
                                                </div>
                                            )}

                                            {subInfo.name === 'svg' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)} style={{
                                                    left: subInfo.left + 'px',
                                                    top: subInfo.top + 'px',
                                                    zIndex: subInfo.z_index,
                                                    transform: subInfo.rotate ? `rotate(${info.rotate}deg)` : 'rotate(0deg)',
                                                    opacity: subInfo.opacity,
                                                }} className='absolute group hover:border-[2px] hover:border-indigo-500'>
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <div className='overflow-hidden' onMouseDown={() => subInfo.moveElement(`${subInfo.id}`, subInfo)}
                                                        id={`${subInfo.id}svg`} style={{
                                                            cursor: 'all-scroll',
                                                            width: subInfo.width + 'px',
                                                            height: subInfo.height + 'px',
                                                            borderRadius: `${subInfo.radius}%`,
                                                            backgroundColor: subInfo.backgroundColor
                                                        }}>
                                                        <svg
                                                            width={subInfo.width + 'px'}
                                                            height={subInfo.height + 'px'}
                                                            version="1.1"
                                                            viewBox={subInfo.viewBox}

                                                        >   <g dangerouslySetInnerHTML={{ __html: subInfo.gInnerHTML }} />
                                                        </svg>
                                                    </div>
                                                    {
                                                        current_component.id === subInfo.id && (
                                                            <div onClick={() => removeComponent(subInfo)} className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                                <BsTrash />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}

                                            {subInfo.name === 'draw' && (
                                                <div id={subInfo.id} name={subInfo.name} mainFrameId={subInfo.mainFrameId} onClick={(e) => handleComponentClick(e, subInfo)}
                                                    style={{
                                                        left: subInfo.left + 'px',
                                                        left: 15 + 'px',
                                                        top: 15 + 'px',
                                                        zIndex: subInfo.z_index,
                                                        opacity: subInfo.opacity,
                                                        transform: subInfo.rotate ? `rotate(${subInfo.rotate}deg)` : 'rotate(0deg)',
                                                        opacity: subInfo.opacity
                                                    }}
                                                    className='absolute group hover:border-[2px] hover:border-indigo-500'
                                                >
                                                    <Element id={subInfo.id} info={subInfo} />
                                                    <div className='overflow-hidden' id={`${subInfo.id}draw`} onMouseDown={() => subInfo.moveElement(`${subInfo.id}`, subInfo)}
                                                        style={{
                                                            cursor: 'all-scroll',
                                                            width: subInfo.width + 'px',
                                                            height: subInfo.height + 'px',
                                                            borderRadius: `${subInfo.radius}%`,
                                                            backgroundColor: subInfo.backgroundColor
                                                        }}>
                                                        <svg
                                                            width={subInfo.width + 'px'}
                                                            height={subInfo.height + 'px'}
                                                            version="1.1"
                                                            viewBox={`0 0 ${subInfo.width} ${subInfo.height}`}
                                                        >
                                                            <path
                                                                d={subInfo.imageData} stroke={subInfo.color} strokeWidth={subInfo.lineWidth} fill="none"
                                                            />
                                                        </svg>
                                                    </div>
                                                    {
                                                        current_component.id === subInfo.id && <div onClick={() => removeComponent(subInfo)}
                                                            className='px-3 py-2 bg-white absolute top-0 hidden group-hover:block cursor-pointer rounded-md'>
                                                            <BsTrash />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </>
                                    )))
                                }
                            </div>
                        </div>
                    </div> */}
                </div >
            </React.Fragment>
        );
    }
    return html;
}
